import React, { useState, useEffect } from 'react';
import { Chess } from 'chess.js';
import ChessboardCustom from '../components/ChessboardCustom';
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import '../firebase-config';
import '../styles/blindfoldPuzzles.css';
import BlindfoldChessboardModal from '../Integrations/BlindfoldChessboardModal';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler

const db = getFirestore();

function BlindfoldPuzzles() {
  const [currentPuzzle, setCurrentPuzzle] = useState(null);
  const [game, setGame] = useState(new Chess());
  const [moveHistory, setMoveHistory] = useState([]);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [playerMove, setPlayerMove] = useState("");
  const [puzzleRating, setPuzzleRating] = useState(1000);
  const [puzzleAccuracy, setPuzzleAccuracy] = useState(0);
  const [puzzleCount, setPuzzleCount] = useState(0);
  const [answeredCorrect, setAnsweredCorrect] = useState(true);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [computerMove, setComputerMove] = useState("");
  const { user } = useUser();
  const [showLoginMessage, setShowLoginMessage] = useState(!user);
  const [isBoardVisible, setIsBoardVisible] = useState(false);
  const [playerColor, setPlayerColor] = useState("white");
  const [isPuzzleComplete, setIsPuzzleComplete] = useState(false);
  const [allMoves, setAllMoves] = useState([]);
  const [initialFenAfterFirstMove, setInitialFenAfterFirstMove] = useState(""); // New state

  const handleInputChange = (event) => {
    const allowedChars = "RNBQK+=xabcdefgh12345678#O-";
    const value = event.target.value;
    let formattedInput = "";

    for (let char of value) {
      if ("rnqko".includes(char)) {
        formattedInput += char.toUpperCase();
      } else if ("ACDEFGH".includes(char)) {
        formattedInput += char.toLowerCase();
      } else if (allowedChars.includes(char)) {
        formattedInput += char;
      }
    }

    setPlayerMove(formattedInput);
  };

useEffect(() => {
  const fetchUserDetails = async () => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const data = userDoc.data();

        // Check if blindfoldPuzzleStats exist and decode them
        if (data.blindfoldPuzzleStats) {
          const { rating, accuracy, count } = decodeblindfoldPuzzleStats(data.blindfoldPuzzleStats);
          setPuzzleRating(rating || 1000);
          setPuzzleAccuracy(accuracy || 0);
          setPuzzleCount(count || 0);
        } else {
          // Default values if blindfoldPuzzleStats do not exist
          setPuzzleRating(1000);
          setPuzzleAccuracy(0);
          setPuzzleCount(0);
        }

        setShowLoginMessage(false);
      }
    }
  };

  fetchUserDetails().then(() => loadPuzzle());
}, [user]);

// Helper function to decode blindfoldPuzzleStats string
const decodeblindfoldPuzzleStats = (blindfoldPuzzleStats) => {
  const ratingMatch = blindfoldPuzzleStats.match(/r(\d+)/);
  const accuracyMatch = blindfoldPuzzleStats.match(/a(\d+(\.\d+)?)/);
  const countMatch = blindfoldPuzzleStats.match(/c(\d+)/);

  const rating = ratingMatch ? parseInt(ratingMatch[1], 10) : 1000;
  const accuracy = accuracyMatch ? parseFloat(accuracyMatch[1]) : 0;
  const count = countMatch ? parseInt(countMatch[1], 10) : 0;

  return { rating, accuracy, count };
};


const loadPuzzle = () => {
  const chunkNumber = Math.floor(Math.random() * 722) + 1; // Assuming calculationpuzzles have 722 chunks
  const url = `${process.env.PUBLIC_URL}/calculationpuzzles/puzzles_chunk_${chunkNumber}.json`;

  fetch(url)
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then(puzzles => {
      const filteredPuzzles = puzzles.filter(puzzle => {
        const pieceCount = puzzle.FEN.split(' ')[0].replace(/\//g, '').replace(/[\d]/g, '').length;
        return pieceCount <= 8 && Math.abs(puzzle.Rating - puzzleRating) <= 50;
      });

      if (filteredPuzzles.length > 0) {
        let selectedPuzzle = filteredPuzzles[Math.floor(Math.random() * filteredPuzzles.length)];

        // Apply LeadingMoves to the FEN if LeadingMoves is present
        if (selectedPuzzle.LeadingMoves) {
          const newGame = new Chess(selectedPuzzle.FEN); // Create a new game from the initial FEN
          const leadingMoves = selectedPuzzle.LeadingMoves.split(' '); // Split the leading moves

          // Apply each move in the leading moves
          leadingMoves.forEach(move => {
            const from = move.substring(0, 2); // 'from' square
            const to = move.substring(2, 4);   // 'to' square
            const promotion = move.length === 5 ? move[4] : undefined; // Optional promotion
            newGame.move({ from, to, promotion }); // Apply the move
          });

          // Overwrite the FEN after applying the leading moves
          selectedPuzzle.FEN = newGame.fen();
        }

        setCurrentPuzzle(selectedPuzzle); // Set the puzzle with the updated FEN
        setIsPuzzleComplete(false); // Reset puzzle completion state
        setAllMoves([]); // Reset the moves history
      } else {
        console.log('No puzzles found within rating range');
      }
    })
    .catch(error => {
      console.error('Failed to load puzzle data:', error);
    });
};


  useEffect(() => {
    if (currentPuzzle?.FEN) {
//      console.log("Loaded Puzzle FEN:", currentPuzzle.FEN);
      const newGame = new Chess(currentPuzzle.FEN);

      const puzzleMoves = currentPuzzle.Moves ? currentPuzzle.Moves.split(" ") : [];
//      console.log("Puzzle Solution Moves:", puzzleMoves);

      setGame(newGame);
      setMoveHistory(puzzleMoves);
      setCurrentMoveIndex(0);
      setAnsweredCorrect(true);
      setPlayerColor(currentPuzzle.FEN.split(' ')[1] === 'w' ? 'black' : 'white');
      setFeedbackMessage("");

      if (puzzleMoves.length > 0) {
        const firstMove = puzzleMoves[0];
        const moveResult = newGame.move(firstMove);  // Make the first move
        setInitialFenAfterFirstMove(newGame.fen()); // Store the FEN after the first move
        setCurrentMoveIndex(1);
        setAllMoves([moveResult.san]);  // Store the first move in algebraic notation
//        console.log("Computer plays first move:", moveResult.san);
      }
    }
  }, [currentPuzzle]);

const handleMoveSubmit = (e) => {
  e.preventDefault();

  const move = playerMove.trim();
  const legalMoves = game.moves();

  if (!legalMoves.includes(move)) {
    setFeedbackMessage("Illegal move! Try again.");
    setAnsweredCorrect(false); // Set this state but don’t adjust rating yet
    return;
  }

  const expectedMove = moveHistory[currentMoveIndex];
  const expectedAlgebraicMove = game.move(expectedMove).san;
  game.undo();

  if (move !== expectedAlgebraicMove) {
    setFeedbackMessage("Incorrect move! Try again.");
    setAnsweredCorrect(false); // Mark it incorrect
    return;
  }

  const moveResult = game.move(move);
  if (!moveResult) {
    setFeedbackMessage("Invalid move! Please check your input.");
    setAnsweredCorrect(false); // Mark it incorrect
    return;
  }

  // Move is correct, so mark it as correct:
  setAnsweredCorrect(true); // Mark correct move, still not adjusting rating

  setCurrentMoveIndex(prevIndex => prevIndex + 1);
  setPlayerMove("");
  setFeedbackMessage("Correct move!");
  setAllMoves(prevMoves => [...prevMoves, moveResult.san]); // Add move to history

  if (currentMoveIndex + 1 < moveHistory.length) {
    const opponentMove = moveHistory[currentMoveIndex + 1];
    game.move(opponentMove); // Play opponent's move
    setCurrentMoveIndex(prevIndex => prevIndex + 1);
    setComputerMove(opponentMove);
    setAllMoves(prevMoves => [...prevMoves, game.history({ verbose: true }).pop().san]);
    setFeedbackMessage('Correct move!');
  } else {
    // Puzzle complete
    setFeedbackMessage("Puzzle complete!");
    setIsPuzzleComplete(true); // Mark puzzle as complete
    adjustRating(); // Call rating adjustment only here when the puzzle ends
  }
};

const handleGiveUp = () => {
  let solution = "";
  for (let i = currentMoveIndex; i < moveHistory.length; i++) {
    const move = game.move(moveHistory[i]);
    if (move) {
      solution += move.san + " ";
    }
  }

  setFeedbackMessage("Solution: " + solution.trim());
  setAllMoves(prevMoves => [...prevMoves, ...solution.trim().split(" ")]); // Add solution moves to history
  setIsPuzzleComplete(true); // Mark the puzzle as complete
  adjustRating(); // Adjust rating after giving up
};


const adjustRating = async (wasSuccessful) => {
  const newPuzzleCount = puzzleCount + 1;
  setPuzzleCount(newPuzzleCount);

  const weightedCount = Math.min(newPuzzleCount, 100);
  const previousSuccessfulPuzzles = (puzzleAccuracy / 100) * (weightedCount - 1);
  const successfulPuzzles = wasSuccessful ? previousSuccessfulPuzzles + 1 : previousSuccessfulPuzzles;

  const newPuzzleAccuracy = (successfulPuzzles / weightedCount) * 100;
  setPuzzleAccuracy(newPuzzleAccuracy);

  let ratingChange;
  const puzzleCountThresholds = [
    { threshold: 10, change: 90 },
    { threshold: 20, change: 50 },
    { threshold: 50, change: 30 },
    { threshold: 100, change: 20 },
    { threshold: Infinity, change: 10 }
  ];

  const foundThreshold = puzzleCountThresholds.find(threshold => puzzleCount < threshold.threshold);
  const baseChange = foundThreshold ? foundThreshold.change : 20;

  if (newPuzzleAccuracy > 86.5 && wasSuccessful) {
    ratingChange = baseChange / 2;
  } else if (newPuzzleAccuracy > 86.5 && !wasSuccessful) {
    ratingChange = -2;
  } else if (newPuzzleAccuracy < 83.5 && !wasSuccessful) {
    ratingChange = -baseChange;
  } else if (newPuzzleAccuracy < 83.5 && wasSuccessful) {
    ratingChange = 2;
  } else if (wasSuccessful) {
    ratingChange = 2;
  } else {
    ratingChange = -2;
  }

  let newPuzzleRating = puzzleRating + ratingChange;

  // Apply the rating change first
  setPuzzleRating(newPuzzleRating);

  // Adding a small delay before updating the rating
  await new Promise((resolve) => setTimeout(resolve, 1500));

  // Clamp the rating if it goes below 400 or above 3200
  if (newPuzzleRating < 400) {
    newPuzzleRating = 400;
  } else if (newPuzzleRating > 3200) {
    newPuzzleRating = 3200;
  }

  setPuzzleRating(newPuzzleRating); // Set clamped rating after the change is triggered

  if (user) {
    try {
      const userRef = doc(db, "users", user.uid);
      
      // Encode the new stats into a single string
      const encodedStats = encodeblindfoldPuzzleStats(newPuzzleRating, newPuzzleAccuracy, newPuzzleCount);
      
      await updateDoc(userRef, {
        blindfoldPuzzleStats: encodedStats
      });
    } catch (error) {
      console.error("Failed to update Firestore:", error);
    }
  }
};

// Helper function to encode the puzzle stats into a string
const encodeblindfoldPuzzleStats = (rating, accuracy, count) => {
  return `r${Math.round(rating)}a${accuracy.toFixed(1)}c${count}`;
};

  const toggleBoardVisibility = () => {
    setIsBoardVisible(!isBoardVisible);
  };

  const whitePieces = game.board().flat().filter(piece => piece && piece.color === 'w');
  const blackPieces = game.board().flat().filter(piece => piece && piece.color === 'b');

  function groupPiecesByType(pieces) {
    return pieces.reduce((acc, piece) => {
      if (!acc[piece.type]) {
        acc[piece.type] = [];
      }
      acc[piece.type].push(piece.square);
      return acc;
    }, {});
  }

  const whitePiecesGrouped = groupPiecesByType(whitePieces);
  const blackPiecesGrouped = groupPiecesByType(blackPieces);

  const renderPieceImage = (piece) => {
    const pieceType = `${piece.color}${piece.type.toUpperCase()}`;
    const imageUrl = `${process.env.PUBLIC_URL}/pieces/normal/${pieceType}.png`;

    return <img src={imageUrl} alt={pieceType} style={{ width: '40px', height: '40px' }} />;
  };

  const lastMove = game.history().length > 0 ? game.history({ verbose: true }).pop().san : '';

  return (
    <div className="blindfold-game-layout">
      {showLoginMessage && (
        <div className="login-message">
          <button className="close-button" onClick={() => setShowLoginMessage(false)}>×</button>
          <p><a href="/login">Log in</a> to save your puzzle data.</p>
        </div>
      )}
      <EnableScrollOnPage />
        <div className="blindfold-info-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='label'>Rating:&nbsp; </span>
            <span>{' '}{puzzleRating}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='label'>Accuracy:&nbsp; </span>
            <span>{puzzleAccuracy ? parseFloat(puzzleAccuracy.toFixed(1)) : '0.0'}%</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='label'>Puzzle Count:&nbsp;</span>
            <span>{puzzleCount}</span>
          </div>

          <button className="toggle-board-button" onClick={toggleBoardVisibility}>
            {isBoardVisible ? "Hide Board" : "Show Board"}
          </button>
        </div>

      {currentPuzzle && (
        <div className="blindfold-puzzle-container">
          <div className='help-link'>
            <a href="/blindfoldpuzzles/help" target="_blank" rel="noopener noreferrer">Help</a>
          </div>
          <h3>Blindfold Puzzle</h3>
          <table className="chess-piece-table">
            <thead>
              <tr>
                <th>White Pieces</th>
                <th>Position(s)</th>
                <th className="black">Black Pieces</th>
                <th className="black">Position(s)</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: Math.max(Object.keys(whitePiecesGrouped).length, Object.keys(blackPiecesGrouped).length) }).map((_, index) => {
                const whitePieceType = Object.keys(whitePiecesGrouped)[index];
                const blackPieceType = Object.keys(blackPiecesGrouped)[index];

                return (
                  <tr key={index}>
                    <td>{whitePieceType ? renderPieceImage({ type: whitePieceType, color: 'w' }) : ''}</td>
                    <td>{whitePieceType ? whitePiecesGrouped[whitePieceType].join(', ') : ''}</td>
                    <td>{blackPieceType ? renderPieceImage({ type: blackPieceType, color: 'b' }) : ''}</td>
                    <td>{blackPieceType ? blackPiecesGrouped[blackPieceType].join(', ') : ''}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p>
            <strong>{playerColor === 'white' ? 'Black' : 'White'} Played:</strong>
            {' '}{isPuzzleComplete ? 'No further moves' : lastMove}
          </p>
        {isBoardVisible && (
          <BlindfoldChessboardModal
            fen={game.fen()}
            playerColor={playerColor}
            onClose={() => setIsBoardVisible(false)} // Function to close the board
          />
        )}

    <form onSubmit={handleMoveSubmit}>
      <label>
        {playerColor.charAt(0).toUpperCase() + playerColor.slice(1)} to Move:
        {'  '}
        <input
          type="text"
          value={playerMove}
          onChange={handleInputChange}
          maxLength={5}
          required={!isPuzzleComplete} // Only required if the puzzle is not complete
        />
      </label>
      {!isPuzzleComplete && (
        <button type="submit">Submit Move</button>
      )}
      {isPuzzleComplete && (
        <button onClick={loadPuzzle} className="next-puzzle-button" type="button">Load Next Puzzle</button>
      )}
      <div className="button-group">
        <button type="button" onClick={handleGiveUp} className="give-up-button2">Give Up</button>
        <button
          type="button"
          onClick={() => window.open(`https://lichess.org/analysis?fen=${encodeURIComponent(initialFenAfterFirstMove)}`, '_blank')} // Use the initial FEN after the first move
          className="give-up-button2"
        >
          Analyze on Lichess
        </button>
      </div>
    </form>

          {feedbackMessage && (
            <p className={`feedback-message ${answeredCorrect ? 'correct' : 'incorrect'}`}>
              {feedbackMessage}
            </p>
          )}
          <div className="move-history">
            <h4>Move History:</h4>
            <ul>
              {allMoves.map((move, index) => (
                <li key={index}>{index + 1}. {move}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlindfoldPuzzles;
