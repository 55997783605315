import React, { useState, useEffect } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import StockFish from '../Integrations/Stockfish';
import '../styles/endgames.css';
import endgameData from '../data/endgames.json';
import ChessboardPreview from '../Integrations/ChessboardPreview';

const Endgames = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [showPreview, setShowPreview] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [hoveredPuzzle, setHoveredPuzzle] = useState(null);
  const [positionStyle, setPositionStyle] = useState({});

  const handleMouseEnter = (game, event) => {
    setHoveredPuzzle(game.fen);
    updatePosition(event);
  };

  const handleMouseLeave = () => {
    setHoveredPuzzle(null);
  };

      const updatePosition = (event) => {
        const button = event.target;  // Assuming this function gets bound to the button's onClick or onMouseEnter
        const rect = button.getBoundingClientRect();
        const isNearBottom = window.innerHeight - rect.bottom < 300;  // 300px is arbitrary based on your preview size

        if (isNearBottom) {
            setPositionStyle({ bottom: '120%', top: 'auto' });
        } else {
            setPositionStyle({ top: '120%', bottom: 'auto' });
        }
        console.log('position style: ' + JSON.stringify(positionStyle));
    };


// Create structured data from JSON for dropdowns
const categories = endgameData.categories.map(category => ({
  name: category.name,
  subcategories: category.subcategories.map(sub => ({
    name: sub.name,
    games: sub.games.map((game, index) => ({
      fen: game.fen,
      puzzleNumber: index + 1, // Puzzle number in each subcategory
      target: game.target // Adding the target parameter
    }))
  }))
}));

const openSelection = () => {
    setShowPreview(true);
}

const [, forceUpdate] = useState();

const handleRestart = () => {
    if (currentGame) {
        setCurrentGame(prevState => ({
            ...prevState,
            settings: {
                ...prevState.settings,
                fen: prevState.settings.fen,
                history: [],
                gameOver: false
            }
        }));
        forceUpdate(Date.now()); // Forces a re-render by updating a state
    } else {
        console.error("No game to restart or game data is incomplete");
    }
};


  const handleGameSelection = (game) => {
    const newGameState = {
      fen: game.fen,
      history: [],
      stockfishLevel: 20,
      blindfold: 'Normal',
      playerColor: 'white',
      gameOver: false,
      target: game.target,
    };

  setCurrentGame({ settings: newGameState });
    setShowPreview(false); // Switch to the game view
  };

const handleCategoryClick = (categoryName) => {
  // Check if the currently selected category is the same as the one clicked
  if (selectedCategory && selectedCategory.name === categoryName) {
    // If it's the same, toggle it off by setting it to null
    setSelectedCategory(null);
    setSelectedSubcategory(null); // Also clear the subcategory selection if any
  } else {
    // Find the category object by matching the category name
    const category = categories.find(cat => cat.name === categoryName);
    setSelectedCategory(category); // Set the selected category in the state
    setSelectedSubcategory(null); // Reset subcategory when switching categories
  }
};

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    // You might want to clear the current game or do some other state cleanup here
  };

return (
  <div className="endgame-wrapper">
    {showPreview ? (
      <div className="endgame-layout">
        <div className="endgame-sidebar">
          {categories.map((category, catIndex) => (
            <div key={catIndex} className="endgame-category">
              <button
                onClick={() => handleCategoryClick(category.name)}
                className={`endgame-category-button ${selectedCategory && selectedCategory.name === category.name ? 'active' : ''}`}
              >
                {category.name}
              </button>
              {selectedCategory && selectedCategory.name === category.name && (
                <div className="endgame-subcategories">
                  {category.subcategories.map((subcategory, subIndex) => (
                    <button
                      key={subIndex}
                      onClick={() => handleSubcategoryClick(subcategory)}
                      className="endgame-subcategory-button"
                    >
                      {subcategory.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="endgame-puzzles">
          {selectedSubcategory && selectedSubcategory.games.map((game, gameIndex) => (
            <div
              key={gameIndex}
              className="endgame-puzzle"
              onMouseEnter={(event) => handleMouseEnter(game, event)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleGameSelection(game)}
            >
              <div className="endgame-puzzle-content">
                {game.puzzleNumber}
              </div>
              <div className='only-desktop'>
              {hoveredPuzzle === game.fen && (
               <div style={{ ...positionStyle, position: 'absolute', right: '90%', width: '100%', boxShadow: '0 0 10px rgba(0,0,0,0.5)', zIndex: 100 }}>
                <ChessboardPreview fen={game.fen} playerColor="white" />
               </div>
              )}
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
        <div className="container">
          {currentGame && (
            <>
                <div className="game-details-container">
                  <p style={{ margin: 0 }}>Goal: {currentGame.settings.target.charAt(0).toUpperCase() + currentGame.settings.target.slice(1)}</p>
                  <div>
                    <button className="endgame-button" onClick={() => {openSelection()}}>
                      Puzzle Selection
                    </button>
                    <button className="endgame-button" onClick={() => {handleRestart()}}>
                      Restart
                    </button>
                  </div>
                </div>
                <div className="board-container">
                <StockFish
                  key={currentGame.settings.fen + Date.now()}
                  skillLevel={currentGame.settings.stockfishLevel}
                  fen={currentGame.settings.fen}
                  playerColor={currentGame.settings.playerColor}
                  history={currentGame.settings.history}
                  endgame='true'
                >
                  {({ position, onDrop, squareStyles, onSquareRightClick, onSquareClick }) => (
                    <ChessboardCustom
                      position={position}
                      onDrop={onDrop}
                      orientation={currentGame.settings.playerColor}
                      squareStyles={squareStyles}
                      onSquareClick={onSquareClick}
                      degreeOfBlindness={currentGame.settings.blindfold}
                      onSquareRightClick={onSquareRightClick}
                    />
                  )}
                </StockFish>
              </div>
            </>
          )}
        </div>
      )}
  </div>
);



};

export default Endgames;

