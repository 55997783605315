import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { UserProvider } from './Integrations/UserContext'; // Import the UserProvider
import Header from './Integrations/Headers/Header';
import HomePage from './pages/HomePage';
import Play from './pages/Play';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/Login';
import Endgames from './pages/Endgames';
import Puzzles from './pages/Puzzles';
import SignUp from './pages/SignUp';
import './firebase-config'; // Ensure Firebase is initialized here if not already done elsewhere
import LoadGame from './pages/LoadGame';
import Settings from './pages/Settings'; // Adjust the import path as needed
import { ThemeProvider } from './Integrations/ThemesContext'; // Import ThemeProvider
import { useNavigate } from 'react-router-dom';
import ThemeApplier from './Integrations/ThemeApplier';
import Journal from './pages/Journal';
import About from './pages/About';
import BlindfoldPuzzles from './pages/BlindfoldPuzzles';
import EndgamePuzzles from './pages/EndgamePuzzles';
import CalculationPuzzles from './pages/CalculationPuzzles';
import MoveTrainer from './pages/MoveTrainer';
import FindFork from './pages/FindFork';
import CoordinateTrainer from './pages/CoordinateTrainer';

// Import the new help components
import Help from './pages/Help/Help'; // Main Help Page
import CalculationPuzzlesHelp from './pages/Help/calculationpuzzles'; // Specific Help Page for Calculation Puzzles
import FindTheForkHelp from './pages/Help/findthefork'; // Specific Help Page for Calculation Puzzles
import CoordinateTrainerHelp from './pages/Help/coordinatetrainer'; // Specific Help Page for Calculation Puzzles
import PlayBlindfoldHelp from './pages/Help/playblindfold'; // Specific Help Page for Calculation Puzzles
import PuzzlesHelp from './pages/Help/puzzles'; // Specific Help Page for Calculation Puzzles
import BlindfoldPuzzlesHelp from './pages/Help/blindfoldpuzzles'; // Specific Help Page for Calculation Puzzles
import ContactPage from './pages/Help/contact'; // Specific Help Page for Calculation Puzzles
import AlgebraicNotation from './pages/Help/algebraicnotation'; // Specific Help Page for Calculation Puzzles
import RatingMetrics from './pages/Help/ratingmetrics'; // Specific Help Page for Calculation Puzzles

function App() {
  return (
    <Router>
      <UserProvider> {/* Wrap the routes with UserProvider */}
        <ThemeProvider> {/* Additionally, wrap the routes with ThemeProvider */}
          <ThemeApplier />
          <RoutesWithHeader />
        </ThemeProvider>
      </UserProvider>
    </Router>
  );
}

const RoutesWithHeader = () => {
  let location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <Header />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/play" element={<PlayWrapper />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/loadgame" element={<LoadGame />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/endgames" element={<Endgames />} />
        <Route path="/journal" element={<Journal />} />
        <Route path="/puzzles" element={<Puzzles />} />
        <Route path="/endgamepuzzles" element={<EndgamePuzzles />} />
        <Route path="/blindfoldpuzzles" element={<BlindfoldPuzzles />} />
        <Route path="/calculationpuzzles" element={<CalculationPuzzles />} />
        <Route path="/findthefork" element={<FindFork />} />
        <Route path="/coordinatetrainer" element={<CoordinateTrainer />} />
        <Route path="/movetrainer" element={<MoveTrainer />} />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<Help />} /> {/* Main Help Page */}
        <Route path="/calculationpuzzles/help" element={<CalculationPuzzlesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/findthefork/help" element={<FindTheForkHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/coordinatetrainer/help" element={<CoordinateTrainerHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/playblindfold/help" element={<PlayBlindfoldHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/puzzles/help" element={<PuzzlesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/blindfoldpuzzles/help" element={<BlindfoldPuzzlesHelp />} /> {/* Calculation Puzzles Help */}
        <Route path="/algebraicnotation" element={<AlgebraicNotation />} /> {/* Calculation Puzzles Help */}
        <Route path="/ratingmetrics" element={<RatingMetrics />} /> {/* Calculation Puzzles Help */}
        <Route path="/contact" element={<ContactPage />} /> {/* Calculation Puzzles Help */}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

// Create a wrapper component for Play to inject the navigate function
const PlayWrapper = () => {
  let navigate = useNavigate();
  return <Play navigate={navigate} />;
}

export default App;
