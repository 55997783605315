//import React, { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
//import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth";
//import '../firebase-config';
//import { signInWithRedirect, getRedirectResult } from "firebase/auth";
//
//const auth = getAuth();
//
//export default function Login() {
//    const [email, setEmail] = useState('');
//    const [password, setPassword] = useState('');
//    const [errorMessage, setErrorMessage] = useState('');
//    const [successMessage, setSuccessMessage] = useState('');
//    const navigate = useNavigate();
//
//    // Handle Firebase auth state changes
//    useEffect(() => {
//        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//            if (currentUser) {
//                console.log("User is logged in:", currentUser);
//                navigate('/'); // Navigate to home after sign-in
//            }
//        });
//        return unsubscribe;
//    }, [navigate]);
//
//    // Handle traditional email/password login
//    const signIn = async (event) => {
//        event.preventDefault();
//        try {
//            const userCredential = await signInWithEmailAndPassword(auth, email, password);
//            console.log('User signed in:', userCredential.user);
//            setErrorMessage('');
//        } catch (error) {
//            setErrorMessage(error.message);
//        }
//    };
//
//    // Handle Google Sign-In
//    const googleSignIn = async () => {
//        const provider = new GoogleAuthProvider();
//        try {
//            // Use Firebase redirect sign-in flow instead of popup
//            await signInWithRedirect(auth, provider);
//        } catch (error) {
//            console.error('Error during Google redirect sign-in:', error.message);
//            setErrorMessage(error.message);
//        }
//    };
//
//    // Handle the redirect result after Google sign-in
//    useEffect(() => {
//        getRedirectResult(auth)
//            .then((result) => {
//                if (result && result.user) {
//                    // This will give you the signed-in user and credential details
//                    const user = result.user;
//                    const credential = GoogleAuthProvider.credentialFromResult(result);
//                    const token = credential.accessToken;
//
//                    console.log('Google sign-in successful:', user);
//                    console.log('Google access token:', token);
//
//                    // Redirect to home page after successful sign-in
//                    navigate('/');
//                }
//            })
//            .catch((error) => {
//                console.error('Error with Google redirect sign-in:', error.message);
//                setErrorMessage(error.message);
//            });
//    }, [navigate]);
//
//    // Handle password reset
//    const resetPassword = async () => {
//        if (!email) {
//            setErrorMessage("Please enter your email first.");
//            return;
//        }
//
//        try {
//            await sendPasswordResetEmail(auth, email);
//            setSuccessMessage("Password reset email sent to entered email if an account exists. Please check your inbox.");
//            setErrorMessage(''); // Clear error messages if any
//        } catch (error) {
//            setErrorMessage(error.message);
//        }
//    };
//
//    // Render Google sign-in button without callback (same as SignUp)
//    useEffect(() => {
//        if (window.google && window.google.accounts) {
//            window.google.accounts.id.initialize({
//                client_id: "110322528888-udb9nk0i9ciqn1re4l8bsi46u1sfdi72.apps.googleusercontent.com",  // Replace with your Google OAuth client ID
//            });
//            window.google.accounts.id.renderButton(
//                document.getElementById("google-signin-button"),
//                { theme: "outline", size: "large", text: "continue_with" }
//            );
//        }
//    }, []);
//
//    return (
//        <>
//            <form onSubmit={signIn}>
//                <div className="login-container">
//                    <h1>Log In</h1>
//                    <input
//                        type="email"
//                        value={email}
//                        onChange={(e) => setEmail(e.target.value)}
//                        placeholder="Email"
//                        autoComplete="email"
//                    />
//                    <input
//                        type="password"
//                        value={password}
//                        onChange={(e) => setPassword(e.target.value)}
//                        placeholder="Password"
//                        autoComplete="current-password"
//                    />
//                    <button type='submit' className='btn-1'>Log In</button>
//
//                    {/* Google Official Sign-In button */}
//                    <div id="google-signin-button" style={{ marginTop: '20px' }}></div>
//
//                    {/* Password Reset Link */}
//                    <p style={{ color: '#333', marginTop: '10px' }}>
//                        <a href="#" onClick={resetPassword} style={{ color: '#333' }}>Forgot your password?</a>
//                    </p>
//
//                    <p style={{ color: '#333', marginTop: '10px' }}>
//                        Don't have an account? <a href="/signup" style={{ color: '#333' }}>Sign up</a>
//                    </p>
//                </div>
//            </form>
//
//            <div className="center-flex">
//                {errorMessage && <p className="error-message">{errorMessage}</p>}
//                {successMessage && <p className="success-message">{successMessage}</p>}
//            </div>
//        </>
//    );
//}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, sendPasswordResetEmail, signInWithRedirect, getRedirectResult } from "firebase/auth";
import '../firebase-config';

const auth = getAuth();

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    // Handle Firebase auth state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                console.log("User is logged in:", currentUser);
                navigate('/'); // Navigate to home after sign-in
            }
        });
        return unsubscribe;
    }, [navigate]);

    // Handle traditional email/password login
    const signIn = async (event) => {
        event.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log('User signed in:', userCredential.user);
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    // Handle Google Sign-In
    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            console.log('Starting Google sign-in with redirect...');
            await signInWithRedirect(auth, provider);
        } catch (error) {
            console.error('Error during Google redirect sign-in:', error.message);
            setErrorMessage(error.message);
        }
    };

    // Handle the redirect result after Google sign-in
    useEffect(() => {
        getRedirectResult(auth)
            .then((result) => {
                if (result && result.user) {
                    const user = result.user;
                    console.log('Google sign-in successful:', user);
                    navigate('/'); // Redirect to home after successful sign-in
                } else {
                    console.log('No user found in the redirect result.');
                }
            })
            .catch((error) => {
                console.error('Error with Google redirect sign-in:', error.message);
                setErrorMessage(error.message);
            });
    }, [navigate]);

    // Handle password reset
    const resetPassword = async () => {
        if (!email) {
            setErrorMessage("Please enter your email first.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMessage("Password reset email sent to entered email if an account exists. Please check your inbox.");
            setErrorMessage(''); // Clear error messages if any
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    return (
        <>
            <form onSubmit={signIn}>
                <div className="login-container">
                    <h1>Log In</h1>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        autoComplete="email"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        autoComplete="current-password"
                    />
                    <button type='submit' className='btn-1'>Log In</button>

                    {/* Password Reset Link */}
                    <p style={{ color: '#333', marginTop: '10px' }}>
                        <a href="#" onClick={resetPassword} style={{ color: '#333' }}>Forgot your password?</a>
                    </p>

                    <p style={{ color: '#333', marginTop: '10px' }}>
                        Don't have an account? <a href="/signup" style={{ color: '#333' }}>Sign up</a>
                    </p>
                </div>
            </form>

            <div className="center-flex">
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </>
    );
}

//                    {/* Custom Google Sign-In Button */}
//                    <button onClick={googleSignIn} className='btn-google-signup' type="button" style={{ marginTop: '20px' }}>
//                        Continue with Google
//                    </button>