import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore methods
import { useUser } from '../Integrations/UserContext'; // Assuming you have a user context
import '../styles/coordinateTrainer.css'; // Custom CSS for styling

const db = getFirestore(); // Initialize Firestore

function CoordinateTrainer() {
    const [game, setGame] = useState(new Chess());
    const [targetSquare, setTargetSquare] = useState(''); // Square the user has to find
    const [playerOrientation, setPlayerOrientation] = useState('white'); // Random orientation
    const [answerSquare, setAnswerSquare] = useState(''); // Track the user's selected square
    const [squareStyles, setSquareStyles] = useState({}); // Style for squares
    const [score, setScore] = useState(0); // Track the current score
    const [isGameActive, setIsGameActive] = useState(false); // Track game state
    const [timeLeft, setTimeLeft] = useState(15); // 15-second timer
    const [bestScore, setBestScore] = useState(null); // Store the user's best score
    const [gameOverMessage, setGameOverMessage] = useState(''); // Message after the game ends
    const { user } = useUser(); // Retrieve the current user

    // Function to generate a random square on the chessboard
    const getRandomSquare = () => {
        const files = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
        const file = files[Math.floor(Math.random() * files.length)];
        const rank = Math.floor(Math.random() * 8) + 1;
        return `${file}${rank}`;
    };

    // Function to generate a random orientation (white or black)
    const getRandomOrientation = () => {
        return Math.random() > 0.5 ? 'white' : 'black';
    };

    // Function to generate the puzzle by choosing a target square and random orientation
    const generatePuzzle = () => {
        const randomSquare = getRandomSquare();
        const randomOrientation = getRandomOrientation();

        setTargetSquare(randomSquare);
        setPlayerOrientation(randomOrientation);
        setAnswerSquare('');
        setSquareStyles({}); // Clear previous square styles
    };

    // Function to flash the board green when the correct square is selected
    const flashBoardGreen = async () => {
        const allSquares = [];
        for (let row = 1; row <= 8; row++) {
            for (let col of 'abcdefgh') {
                allSquares.push(col + row);
            }
        }

        const flashStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "rgba(0, 255, 0, .5)" };
            return styles;
        }, {});

        const defaultStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "transparent" };
            return styles;
        }, {});

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 300));
        setSquareStyles(defaultStyle);
        await new Promise(resolve => setTimeout(resolve, 50));
    };

    // Function to flash the board red when the incorrect square is selected
    const flashBoardRed = async () => {
        const allSquares = [];
        for (let row = 1; row <= 8; row++) {
            for (let col of 'abcdefgh') {
                allSquares.push(col + row);
            }
        }

        const flashStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "rgba(255, 0, 0, .5)" };
            return styles;
        }, {});

        const defaultStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "transparent" };
            return styles;
        }, {});

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 400));
        setSquareStyles(defaultStyle);
    };

    // Function to handle user's square selection
    const handleSquareClick = (square) => {
        // Handle free play or game mode
        if (square === targetSquare) {
            flashBoardGreen(); // Flash the board green for correct answer
            if (isGameActive) {
                setScore(prevScore => prevScore + 1); // Increment score only if in game mode
            }
            setTimeout(generatePuzzle, 100); // Move to next puzzle after a delay
        } else {
            flashBoardRed(); // Flash the board red for incorrect answer
        }
        setAnswerSquare(square); // Highlight the user's clicked square
    };

    // Start a new game and reset the score
    const startGame = () => {
        setScore(0);
        setTimeLeft(15);
        setIsGameActive(true);
        generatePuzzle();
    };

    // End the game and save the score if it's a new record
    const endGame = async () => {
        setIsGameActive(false);
        if (score > bestScore) {
            setBestScore(score);
            setGameOverMessage(`New record! Your new best score is ${score}.`);
            if (user) {
                const userRef = doc(db, "users", user.uid);
                await setDoc(userRef, { bestCoordinateScore: score }, { merge: true });
            }
        } else if (score === bestScore) {
            setGameOverMessage(`You tied your best score of ${bestScore}!`);
        } else {
            setGameOverMessage(`Your score was ${score}. Best score: ${bestScore}. Keep trying!`);
        }
    };

    // Countdown timer for the game
    useEffect(() => {
        if (isGameActive && timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else if (isGameActive && timeLeft === 0) {
            endGame();
        }
    }, [timeLeft, isGameActive]);

    // Load the user's best score from Firebase
    useEffect(() => {
        const fetchBestScore = async () => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setBestScore(userDoc.data().bestCoordinateScore || 0);
                } else {
                    setBestScore(0);
                }
            }
        };

        fetchBestScore();
    }, [user]);

    useEffect(() => {
        const newGame = new Chess();
        setGame(newGame);
        generatePuzzle();
    }, []);

    return (
        <div className="game-layout">
            <div className="chessboard-container">
                <ChessboardCustom
                    position={game.fen()}
                    orientation={playerOrientation}
                    onSquareClick={handleSquareClick}
                    squareStyles={squareStyles} // Highlight selected square or flash feedback
                    degreeOfBlindness="Normal"
                />
            </div>
            <div className="feedback-container">
                <Link to="/coordinatetrainer/help" target="_blank" className="help-link">
                    Help
                </Link>
                <p>{isGameActive ? `Time Left: ${timeLeft}s` : `Best Score: ${bestScore || 'N/A'}`}</p>
                {gameOverMessage && <p>{gameOverMessage}</p>}
                {!isGameActive && <button onClick={startGame} className="puzzle-button2">Start Game</button>}
                <p>Click on the square: <strong style={{ fontSize: '24px' }}>{targetSquare}</strong></p>
            </div>
        </div>
    );
}

export default CoordinateTrainer;
