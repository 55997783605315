import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import '../firebase-config';

const auth = getAuth();
const db = getFirestore();
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState('');

    useEffect(() => {
        // Set the persistence for the auth session
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                const unsubscribe = onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        const userRef = doc(db, "users", user.uid);
                        const userSnap = await getDoc(userRef);

                        if (userSnap.exists()) {
                            const data = userSnap.data();
                            console.log('User Verified in user context.');

                            // Set the user details including endgamePuzzleRating and calculationPuzzleRating
                            setUsername(data.username || "Anonymous");

                        } else {
                            // Create a new document if it doesn't exist
                            await setDoc(userRef, {
                                username: "Anonymous",

                            });
                            console.log('Default values set in user context');
                            setUsername("Anonymous");
                        }
                        setUser(user);
                    } else {
                        console.log('Default values set in user context, option 2');
                        setUser(null);
                        setUsername('Guest');
                    }
                });
                return () => unsubscribe(); // Cleanup subscription on unmount
            })
            .catch((error) => {
                console.error("Firebase auth persistence error:", error);
            });

    }, []);

    return (
        <UserContext.Provider value={{ user, username }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
