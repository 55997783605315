import React from 'react';
import '../../styles/help.css'; // Reusing the same style file for consistency
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const ContactPage = () => {
  return (
    <div className="help-container">
      <EnableScrollOnPage />
      <h1>Contact Us</h1>
      <p>If you have any questions or need assistance, feel free to reach contact us via email at: <a href="mailto:contact@trainchess.net">contact@trainchess.net</a></p>

      {/* Social Media Section */}
      <section className="contact-section">
        <h2>Follow Us on Social Media</h2>
        <ul>
          <li>
            <a href="https://www.instagram.com/train.chess?igsh=MjNsanZ6bDM4NG91&utm_source=qr" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCO7Guyli8rWF3CqVOIM1nZA" target="_blank" rel="noopener noreferrer">
              YouTube
            </a>
          </li>
          {/* Add other social media platforms as needed */}
        </ul>
      </section>

      {/* About Link */}
      <section className="further-help">
        <h3>Learn More About Us</h3>
        <p>For more information about our team and our mission, visit our <a href="/about" className="further-help-link">About Page</a>.</p>
      </section>
    </div>
  );
};

export default ContactPage;
