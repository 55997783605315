import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const FindTheForkHelp = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Find the Fork</h1>

      <section className="how-to-solve">
        <h2>What is Find the Fork?</h2>
        <p><a href="/findthefork">Find the Fork</a> helps you practice identifying knight forks, one of the trickiest moves in chess. A <strong>fork</strong> occurs when a single piece attacks two or more enemy pieces at the same time. In this exercise, you'll need to find the square where a knight can fork key pieces like the king and queen.</p>
        <div className="screenshot-container">
          <p><strong>Example Fork:</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/fork.png`} alt="Fork Example" className="screenshot" />
        </div>
        <p>Your task is to find the square where a knight can land to attack two or more enemy pieces. Once you've identified the fork square, simply click on it to make the move and test your solution.</p>
        <div className="screenshot-container">
          <p><strong>Selecting the Fork Square:</strong></p>
          <img src={`${process.env.PUBLIC_URL}/help/forkSquare.png`} alt="Select Fork Square" className="screenshot" />
        </div>
        <p>If you select the correct square, the board will flash <strong>green</strong>, and you’ll move on to the next puzzle. If your selection is incorrect, the board will flash <strong>red</strong>, allowing you to try again.</p>
      </section>

      <section className="game-modes">
        <h2>Game Mode vs. Free Practice</h2>
        <p>There are two ways to play Find the Fork: <strong>Game Mode</strong> and <strong>Free Practice Mode</strong>.</p>

        <h3>Free Practice Mode</h3>
        <p>In Free Practice Mode, you can take your time to find the correct fork without worrying about time or scoring. This mode is great for focusing on improving your pattern recognition and tactical skills.</p>

        <h3>Game Mode</h3>
        <p>If you hit <strong>Start Game</strong> you'll be challenged to find as many forks as possible within a set time limit. Your score and best score will be displayed at the end of each session. If you set a new high score, you’ll be notified!</p>
      </section>

      <section className="benefits-section">
        <h2>Why Practice Finding Forks?</h2>
        <ul>
          <li><strong>Enhanced Tactical Awareness:</strong> Forks are a critical part of chess tactics, and regularly practicing them improves your ability to spot tactical opportunities during a game.</li>
          <li><strong>Improved Board Vision:</strong> Regularly identifying forks helps you visualize the board more effectively, allowing you to anticipate and execute tactics faster.</li>
          <li><strong>Increased Calculation Speed:</strong> With practice, you’ll be able to calculate potential knight moves and forks more quickly, improving your overall tactical skill.</li>
        </ul>
      </section>

      <section className="further-help">
        <h2>Need More Help?</h2>
        <p>If you have more questions or need further assistance, feel free to check the <a href="/help">main help page</a> or <a href="/contact">contact us</a> for additional resources.</p>
      </section>
    </div>
  );
};

export default FindTheForkHelp;
