import React, { useState, useEffect } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import FileStructure from '../Integrations/FileStructure';
import SaveLineModal from '../Integrations/SaveLineModal'; // Import the modal component
import '../styles/moveTrainer.css'; // Add styling for the layout
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';

const db = getFirestore();

const DEFAULT_START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const MoveTrainer = () => {
  const [chessboardSize, setChessboardSize] = useState(600);
  const [game, setGame] = useState(new Chess(DEFAULT_START_FEN)); // Initialize game with start FEN
  const [userColor, setUserColor] = useState('white'); // Let user choose which side to play
  const [correctMoves, setCorrectMoves] = useState([]); // Store the correct move sequence (used in training mode)
  const [moveIndex, setMoveIndex] = useState(0); // Track user's progress through the move sequence
  const [squareStyles, setSquareStyles] = useState({}); // Store styles for flashing feedback
  const [isFinished, setIsFinished] = useState(false); // Track whether training is complete
  const [percentageCorrect, setPercentageCorrect] = useState(0); // Track correctness percentage
  const [moves, setMoves] = useState([]); // Record user's moves (used in add-line mode)
  const [showSaveModal, setShowSaveModal] = useState(false); // Control visibility of SaveLineModal
  const { user } = useUser(); // Get the current user
  const [directories, setDirectories] = useState([]); // Store user's directories
  const [mode, setMode] = useState('train'); // Track whether we're in add-line or train mode

  useEffect(() => {
    if (user) {
      fetchDirectories();
    }
  }, [user]);

  const fetchDirectories = async () => {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setDirectories(data.directories || []);
    } else {
      console.log('No such document!');
    }
  };

  // Function to parse the PGN and extract the moves, then convert to square notation (for training mode)
  const extractMovesFromPgn = (pgn) => {
    const newGame = new Chess();
    newGame.loadPgn(pgn); // Load PGN into a temporary game instance
    const history = newGame.history({ verbose: true }); // Extract verbose move history

    const moveObjects = history.map((move) => ({
      from: move.from, // e.g., 'e2'
      to: move.to,     // e.g., 'e4'
      nextMove: null,  // Placeholder for chaining next moves
    }));

    // Chain nextMove to each move
    for (let i = 0; i < moveObjects.length - 1; i++) {
      moveObjects[i].nextMove = moveObjects[i + 1];
    }

    return moveObjects;
  };

  // Handle file selection (from FileStructure component)
  const handleFileSelect = (fileData) => {
    setIsFinished(false); // Reset the completion state
    const fen = fileData.fen || DEFAULT_START_FEN; // Use provided FEN or default start position FEN

    // Ensure the FEN is valid before starting a game
    const isValidFen = fen.split(' ').length === 6; // Validate FEN (must contain 6 space-separated fields)
    const newGame = new Chess(isValidFen ? fen : DEFAULT_START_FEN); // Create a new Chess instance

    // Extract the move sequence from the PGN and convert to square notation
    const pgnMoves = extractMovesFromPgn(fileData.pgn);

    setCorrectMoves(pgnMoves); // Store the correct move sequence
    setMoveIndex(0); // Reset the move index
    setGame(newGame); // Set the new game state
    setSquareStyles({}); // Reset the square styles for flashing feedback
    setMode('train'); // Switch to train mode when a line is selected
  };

  // Restart the current line (used in training mode)
  const restartLine = () => {
    setMoveIndex(0); // Reset the move index
    const newGame = new Chess(DEFAULT_START_FEN); // Reset the game to the initial FEN
    setGame(newGame); // Set the new game state
    setIsFinished(false); // Reset the completion state
    setSquareStyles({}); // Reset the square styles for flashing feedback
    setMoves([]); // Reset recorded moves
  };

  // Free move handling for add-line mode
  const handleAddLineMove = ({ sourceSquare, targetSquare }) => {
    const moveResult = game.move({ from: sourceSquare, to: targetSquare });
    if (moveResult) {
      setMoves((prevMoves) => [...prevMoves, moveResult.san]); // Record moves in SAN format
      setGame(game); // Simply trigger a re-render with the current game state
    }
  };

  // Practice moves with feedback and record user's moves (used in training mode)
  const handleTrainingMove = ({ sourceSquare, targetSquare }) => {
    const currentMove = correctMoves[moveIndex];

    if (currentMove && currentMove.from === sourceSquare && currentMove.to === targetSquare) {
      game.move({ from: sourceSquare, to: targetSquare });
      setMoveIndex(moveIndex + 1); // Move to the next move

      // Flash feedback for correct move
      flashFeedback('green', sourceSquare, targetSquare);

      if (moveIndex + 1 >= correctMoves.length) {
        setIsFinished(true); // Mark training as complete
        const correctPercentage = Math.round(((moveIndex + 1) / correctMoves.length) * 100);
        setPercentageCorrect(correctPercentage); // Calculate percentage of correct moves
      }

      // If it's the opponent's move, make it automatically after a delay
      if (currentMove.nextMove) {
        setTimeout(() => {
          game.move({ from: currentMove.nextMove.from, to: currentMove.nextMove.to });
          setMoveIndex(moveIndex + 2); // Move to the user's next move
          flashFeedback('green', currentMove.nextMove.from, currentMove.nextMove.to);
        }, 700); // Delay for 0.7 seconds before the opponent's move
      }
    } else {
      // Incorrect move by the user
      flashFeedback('red', sourceSquare, targetSquare);
    }
  };

  // Flash feedback function for correct/incorrect moves
  const flashFeedback = (color, fromSquare, toSquare) => {
    const highlightStyle =
      color === 'green'
        ? { backgroundColor: 'rgba(0, 255, 0, 0.6)' }
        : { backgroundColor: 'rgba(255, 0, 0, 0.6)' };

    const newSquareStyles = {
      ...squareStyles,
      [fromSquare]: highlightStyle,
      [toSquare]: highlightStyle,
    };

    setSquareStyles(newSquareStyles); // Apply flashing styles

    setTimeout(() => {
      // Reset square styles after flashing
      setSquareStyles({});
    }, 500); // Flash for half a second
  };

  // Handle saving the line in Add-Line Mode
  const handleSaveLine = async (lineName, directory) => {
    if (!user) {
      alert('Please log in to save your lines.');
      return;
    }

    const pgn = game.pgn({ maxWidth: 80, newline_char: "\n" }); // Get the PGN of the current game

    if (pgn.trim() === "") {
      alert('No moves have been made. Please make moves before saving the line.');
      return;
    }

    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);

    let data = {};
    if (docSnap.exists()) {
      data = docSnap.data();
    }

    // Update directories
    const updatedDirectories = new Set(data.directories || []);
    updatedDirectories.add(directory);

    // Update MoveTrainer
    const MoveTrainer = data.MoveTrainer || {};
    if (!MoveTrainer[directory]) {
      MoveTrainer[directory] = [];
    }

    // Save the line with only PGN (FEN is included in PGN if needed)
    MoveTrainer[directory].push({ name: lineName, pgn });

    // Save to Firestore
    await setDoc(
      docRef,
      {
        directories: Array.from(updatedDirectories),
        MoveTrainer,
      },
      { merge: true }
    );

    setShowSaveModal(false);
    setMoves([]);
    fetchDirectories(); // Refresh directories
  };

  return (
    <div className="move-trainer-wrapper">
      <div className="file-structure-sidebar">
        <FileStructure onFileSelect={handleFileSelect} directories={directories} />
      </div>
      <div className="chessboard-area full-width">
        <>
          <h2>{mode === 'addLine' ? 'Add a New Line' : 'Train a Line'}</h2>

          <ChessboardCustom
            position={game.fen()}
            onDrop={mode === 'addLine' ? handleAddLineMove : handleTrainingMove} // Handle moves differently based on mode
            orientation={userColor}
            squareStyles={squareStyles} // Apply square styles for flashing feedback
            updateChessboardSize={setChessboardSize}
            degreeOfBlindness='Normal'
          />

          {mode === 'addLine' && (
            <div className="controls">
              <button onClick={() => {
                const newGame = new Chess();
                setGame(newGame); // Start a new game when adding a line
                setMoves([]); // Clear any previous moves
              }}>
                Start New Line
              </button>
              <button onClick={() => setShowSaveModal(true)}>Save Line</button>
              <button onClick={() => setMode('train')}>Switch to Train Mode</button>
            </div>
          )}

          {mode === 'train' && !isFinished && (
            <div className="controls">
              <button onClick={() => setMode('addLine')}>Switch to Add-Line Mode</button>
            </div>
          )}

          {isFinished && (
            <div className="result-message">
              <h3>Training Complete!</h3>
              <p>
                You got <strong>{percentageCorrect}%</strong> of the moves correct.
              </p>
              <button onClick={restartLine}>Retry</button>
            </div>
          )}
        </>
        {showSaveModal && (
          <SaveLineModal
            moves={moves}
            onSave={handleSaveLine}
            onClose={() => setShowSaveModal(false)}
            directories={directories}
          />
        )}
      </div>
    </div>
  );
};

export default MoveTrainer;
