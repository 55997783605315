import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chess } from "chess.js"; // import Chess from  "chess.js"(default) if recieving an error about new Chess not being a constructor
import GameOverModal from './GameOverModal'; // Adjust the path as necessary
import { openingBook } from './OpeningBook';
import PromotionModal from './PromotionModal';
import { renderMoveHistory } from './MoveHistoryTable';

const STOCKFISH = window.STOCKFISH;
const game = new Chess();

class Stockfish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endgame: (this.props.endgame ? true : false),
      initialFen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
      fen: this.props.fen,
      displayFen: null,
      isViewingCurrentPosition: true,
      isPromotionOpen: false,
      promotion: 'q',
      promotionDetails: null,
      history: this.props.history,
      dropSquareStyle: {},
      squareStyles: {},
      pieceSquare: "",
      legalMoves: [],
      hasResigned: false,
      gameOver: this.props.gameOver,
      gameOverOpen: false,
      gameResultMessage: '',
      gameResult: '',
      showKeyboardButtons: false,
      showShareOptions: false,
      datePlayed: null,
      capturedPieces: {
            white: [],
            black: []
          }
    };
    this.moveHistoryRef = React.createRef();
    this.shareButtonRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.handlePromotion = this.handlePromotion.bind(this);
    this.engineGameInstance = this.engineGame();
    this.handleEngineOutput = this.handleEngineOutput.bind(this);
  }

//async componentDidMount() {
//  document.addEventListener('mousedown', this.handleClickOutside);
//
//  // Check if history is provided through props and load it
//  if (this.props.history) {
//    await this.loadGameFromHistory(this.props.history);
//    this.engineGame().prepareMove();
//    console.log('there is history');
//  }
//}

async componentDidMount() {
  game.reset()
  this.setDatePlayed();
  document.addEventListener('mousedown', this.handleClickOutside);
  this.engineGameInstance.start();
  // Check if history is provided through props and it has at least one move
    if (this.props.history && this.props.history.length > 0) {
        await this.loadGameFromHistory(this.props.history);
//        console.log('Game loaded from history');
    } else if (this.state.fen && this.state.fen !== 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1') {
        // Load the game from an initial FEN if no history is present
        game.load(this.state.fen);
        this.setState({ initialFen: this.state.fen }); // Set initialFen to this FEN to ensure it doesn't change unless needed
//        console.log('Game loaded from FEN:', this.state.fen);
    }
  setTimeout(() => {
    if (this.engineGame) {
//      this.engineGame().prepareMove();
      this.engineGameInstance.prepareMove();
//      console.log('Engine move prepared after delay');
    } else {
      console.error("Engine game instance or prepareMove function is not correctly defined.");
    }
  }, 1000);  // Adjust the timeout duration as needed  console.log('Component mounted, engine game instance started');
}


loadGameFromHistory = (history) => {
  game.reset(); // Reset the game to its initial state

  // Apply each move in the history to the game
  history.forEach(moveSAN => {
    const move = game.move(moveSAN, { sloppy: true });
    if (!move) {
      console.error("Invalid or illegal move in history:", moveSAN);
      return;
    }
  this.setState({
      fen: game.fen(),
      history: game.history({ verbose: true }),
      pieceSquare: "",
      selectedMoveIndex: game.history().length - 1,
  });
  });

  this.setState({
    fen: game.fen(),
    history: game.history({ verbose: true }) // or just history if you store moves as SAN strings
  }, () => {
    console.log("Game loaded from history:", game.fen());
  });
};

componentDidUpdate(prevProps, prevState) {
  const moveHistoryElement = this.moveHistoryRef.current;
  if (moveHistoryElement) {
    moveHistoryElement.scrollTop = moveHistoryElement.scrollHeight;
  }
  if (prevState.selectedMoveIndex !== this.state.selectedMoveIndex) {
    this.scrollToSelectedMove();
  }
}

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
//    this.engineGameInstance.terminate();
//    console.log('component did unmount');
  }

handleClickOutside = (event) => {
  if (
    this.shareButtonRef.current &&
    !this.shareButtonRef.current.contains(event.target) &&
    this.dropdownRef.current &&
    !this.dropdownRef.current.contains(event.target)
  ) {
    this.setState({ showShareOptions: false });
  }
};


scrollToSelectedMove = () => {
  const { selectedMoveIndex } = this.state;
  const moveHistoryElement = this.moveHistoryRef.current;
  if (moveHistoryElement) {
    const rowHeight = 17;
    const scrollTop = selectedMoveIndex * rowHeight;
    moveHistoryElement.scrollTop = scrollTop - (moveHistoryElement.offsetHeight / 2) + (rowHeight / 2); // Center the selected move in the scroll view
  }
}

//copyFenToClipboard = () => {
//  const fen = this.state.fen; // Assuming 'fen' is stored in your component's state
//  if (!navigator.clipboard) {
//    // Clipboard API not available
//    console.error('Clipboard API not available.');
//    return;
//  }
//  navigator.clipboard.writeText(fen).then(() => {
////    console.log('FEN copied to clipboard:', fen);
//    // Optionally, notify the user that the FEN was successfully copied
//  }).catch(err => {
//    console.error('Failed to copy FEN to clipboard:', err);
//    // Optionally, notify the user that there was an error copying the FEN
//  });
//};

  setDatePlayed = () => {
    const today = new Date();
    const datePlayed = `${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()}`;
    this.setState({ datePlayed });
  };

generatePgnTags = () => {
  const { eventName, datePlayed } = this.state; // Assuming these exist in your state
//    console.log('Player color: ' + this.props.playerColor + ' Game Over? ' + this.state.gameOver + ' Result Message: ' + this.state.gameResultMessage)

  const whiteName = this.props.playerColor === "white" ? "You" : "Stockfish";
  const blackName = this.props.playerColor === "black" ? "You" : "Stockfish";
  const result = this.state.gameOver ? this.state.gameResult : "*"; // Use your game's state to determine result

  const tags = [
    `[Event "${eventName || 'Casual Game'}"]`,
    `[Site "Train Chess"]`,
    `[Date "${datePlayed || '????.??.??'}"]`,
    `[Round "?"]`,
    `[White "${whiteName}"]`,
    `[Black "${blackName}"]`,
    `[Result "${result}"]`,
  ];

  return tags.join("\n") + "\n\n";
};


generatePgn = () => {
  const tags = this.generatePgnTags();
  const movesPgn = game.pgn({
    max_width: 5,
    newline_char: "\n",
  });

  return tags + movesPgn;
};

copyPgnToClipboard = () => {
  const pgn = this.generatePgn(); // Assuming you have a method that generates the PGN string of the game
  if (!navigator.clipboard) {
    // Clipboard API not available
    console.error('Clipboard API not available.');
    return;
  }
  navigator.clipboard.writeText(pgn).then(() => {
//    console.log('PGN copied to clipboard:', pgn);
    // Optionally, notify the user that the PGN was successfully copied
  }).catch(err => {
    console.error('Failed to copy PGN to clipboard:', err);
    // Optionally, notify the user that there was an error copying the PGN
  });
};


openInLichess = () => {
  const fen = this.state.fen; // Assuming 'fen' holds the current game position
  const url = `https://lichess.org/analysis/standard/${encodeURIComponent(fen)}?openBrowser=true`;
  window.open(url, "_blank"); // Open the URL in a new tab
};

  handleMove = (from, to, promotion) => {
    if (this.state.gameOver) {
     return;
    }
    if (!this.state.isViewingCurrentPosition) {
    // Prevent making moves if not viewing the current position
    return;
  }
    game.move({ from, to, promotion });

    const newSquareStyles = squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) });
    const checkHighlightStyles = this.updateCheckHighlight(); // Get styles for highlighting check
    const combinedSquareStyles = { ...newSquareStyles, ...checkHighlightStyles }; // Combine styles

    this.setState({
      fen: game.fen(),
      history: game.history({ verbose: true }),
      pieceSquare: "",
      squareStyles: combinedSquareStyles,
      selectedMoveIndex: game.history().length - 1,
    }, () => {
      this.announceResult();
//      this.engineGame().prepareMove();
      this.engineGameInstance.prepareMove();
      this.props.updateHistory(game.history());
      this.props.updateFen(this.state.fen);
    });
//    console.log("History: " + this.state.history);
  }

calculateMaterialAdvantage(fen) {
  const pieceValues = { 'p': 1, 'n': 3, 'b': 3, 'r': 5, 'q': 9 };
  let whiteMaterial = 0;
  let blackMaterial = 0;

  // Extract the piece positions from the FEN string
  const pieces = fen.split(' ')[0];

  // Iterate through each character in the FEN piece placement
  for (const char of pieces) {
    if (char in pieceValues) {
      // Add value if the piece is black (lowercase in FEN denotes black pieces)
      blackMaterial += pieceValues[char];
    } else if (char.toLowerCase() in pieceValues) {
      // Add value if the piece is white (uppercase in FEN denotes white pieces)
      whiteMaterial += pieceValues[char.toLowerCase()];
    }
    // Ignore slashes and numbers (which denote empty squares) in FEN
  }

  // Material advantage: positive if white has more material, negative if black has more
  return whiteMaterial - blackMaterial;
}

calculateDifferentialCaptures(fen) {
  // Starting counts for a full chess set
  const startingCounts = { P: 8, R: 2, N: 2, B: 2, Q: 1, K: 1 };
  // Current counts initialized to 0
  const currentCounts = { w: { P: 0, R: 0, N: 0, B: 0, Q: 0, K: 0 }, b: { P: 0, R: 0, N: 0, B: 0, Q: 0, K: 0 } };

  // Parse the piece placement part of the FEN
  const piecesPlacement = fen.split(' ')[0];
  piecesPlacement.replace(/\d/g, '').split('').forEach(piece => {
    // Increment count for white and black pieces
    if (currentCounts.w[piece.toUpperCase()] !== undefined) {
      piece === piece.toUpperCase() ? currentCounts.w[piece]++ : currentCounts.b[piece.toUpperCase()]++;
    }
  });

  // Calculate differential captures, ensuring we don't show duplicates
  const differentialCaptures = { white: {}, black: {} };
  Object.keys(startingCounts).forEach(piece => {
    const whiteCaptures = startingCounts[piece] - currentCounts.w[piece];
    const blackCaptures = startingCounts[piece] - currentCounts.b[piece];

    // Calculate the differential for each piece type
    const differential = whiteCaptures - blackCaptures;

    if (differential > 0) {
      // If positive, white has captured more of this piece
      differentialCaptures.white[piece] = differential;
    } else if (differential < 0) {
      // If negative, black has captured more of this piece
      differentialCaptures.black[piece] = -differential; // Use the positive value of the differential
    }
    // If differential is 0, it means an equal number of pieces were captured, so we don't show these pieces
  });

  return differentialCaptures;
}



renderCapturedPieces() {
  const materialAdvantage = this.calculateMaterialAdvantage(this.state.fen);
  const differentialCaptures = this.calculateDifferentialCaptures(this.state.fen);

  const normalPieceImages = {
  wP: process.env.PUBLIC_URL + "pieces/normal/wP.png",
  wR: process.env.PUBLIC_URL + "pieces/normal/wR.png",
  wN: process.env.PUBLIC_URL + "pieces/normal/wN.png",
  wB: process.env.PUBLIC_URL + "pieces/normal/wB.png",
  wQ: process.env.PUBLIC_URL + "pieces/normal/wQ.png",
  wK: process.env.PUBLIC_URL + "pieces/normal/wK.png",
  bP: process.env.PUBLIC_URL + "pieces/normal/bP.png",
  bR: process.env.PUBLIC_URL + "pieces/normal/bR.png",
  bN: process.env.PUBLIC_URL + "pieces/normal/bN.png",
  bB: process.env.PUBLIC_URL + "pieces/normal/bB.png",
  bQ: process.env.PUBLIC_URL + "pieces/normal/bQ.png",
  bK: process.env.PUBLIC_URL + "pieces/normal/bK.png",
};

const renderPieceImages = (color) => {
  // Convert 'w' or 'b' to uppercase for image retrieval
  const colorPrefix = color === 'white' ? 'w' : 'b';

  return Object.entries(differentialCaptures[color]).flatMap(([pieceType, count]) =>
    Array.from({ length: count }, (_, i) => {
      const imageKey = `${colorPrefix}${pieceType.toUpperCase()}`;
      return (
        <img
          key={`${color}-${pieceType}-${i}`}
          src={normalPieceImages[imageKey]}
          alt={`${colorPrefix}${pieceType.toUpperCase()}`}
          style={{ width: '20px', margin: '2px' }}
        />
      );
    })
  );
};

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {renderPieceImages('white')}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {renderPieceImages('black')}
      </div>

      {/* Material advantage display */}
        <div>
          {materialAdvantage > 0 ? (
            <span style={{ color: 'gray', fontWeight: 'bold' }}>+{materialAdvantage}</span>
          ) : materialAdvantage < 0 ? (
            <span style={{ color: 'gray', fontWeight: 'bold' }}>{materialAdvantage}</span>
          ) : ''}
        </div>
    </div>
  );
}

  handlePromotion(piece) {
    // Set the state with the selected promotion piece
    const { sourceSquare, targetSquare } = this.state.promotionDetails;
    this.setState({ promotion: piece }, () => {
      // Close the promotion modal and continue with the promotion move
//      console.log(`Selected promotion piece: ${this.state.promotion}`);
      // You might want to continue with making the actual promotion move here
    this.handleMove(sourceSquare, targetSquare, this.state.promotion)
    this.setState({promotion: 'q', promotionDetails: null, isPromotionOpen: false})
    });
  }

isPromotionMove = (sourceSquare, targetSquare) => {
  // Determine the rank (row) of the target square
  const targetRank = targetSquare[1]; // Get the rank part of the square

  // Determine if the source square contains a pawn
  const pieceOnSource = game.get(sourceSquare);
  if (!pieceOnSource || pieceOnSource.type !== 'p') {
    return false; // Not a pawn, so it can't be a promotion move
  }

  const isPawn = pieceOnSource.type === 'p';
  const color = pieceOnSource.color;

  // Check for white pawn promotion to rank 8 or black pawn to rank 1
  if (isPawn && ((color === 'w' && targetRank === '8') || (color === 'b' && targetRank === '1'))) {
    return true;
  } else {
    // If neither condition is met, it's not a promotion move
    return false;
  }
};


onDrop = ({ sourceSquare, targetSquare }) => {

  this.setState({promotion: 'q', promotionDetails: null, isPromotionOpen: false})
  // Fetch all legal moves for the current game state in a verbose format
  const legalMoves = game.moves({ verbose: true });

  // Check if the attempted move is legal
  const moveIsLegal = legalMoves.some(move => move.from === sourceSquare && move.to === targetSquare);

  if (!moveIsLegal) {
    // If the move isn't legal, return 'snapback' to revert the piece movement
    return 'snapback';
  }

  // Determine if the move is a promotion and handle accordingly
  const isPromotion = this.isPromotionMove(sourceSquare, targetSquare, game.get(sourceSquare));
//  let promotion = 'q'; // Default promotion to queen for simplicity
  if (isPromotion) {
    this.setState({isPromotionOpen: true, promotionDetails: {sourceSquare, targetSquare} });
  }
  else {
    this.handleMove(sourceSquare, targetSquare, this.state.promotion)
  }
};


onSquareClick = square => {

    this.setState({promotion: 'q', promotionDetails: null, isPromotionOpen: false})
    if (this.state.gameOver) {
     return;
    }
  const piece = game.get(square); // Retrieve the piece at the clicked square

  // If the clicked square has a piece and it's the player's color, highlight possible moves
  if (piece && piece.color === this.props.playerColor[0]) {
    // Calculate possible moves for the selected piece
    const moves = game.moves({ square: square, verbose: true });
    const squaresToHighlight = moves.map(move => move.to); // Get squares to highlight based on possible moves

// Function to determine if a square contains a piece
const hasPiece = (square) => {
  const piece = game.get(square);
  return piece;
};

const darkSquare = 'var(--dark-square-color)'; // Example dark square color
const lightSquare = 'var(--light-square-color)'; // Example light square color

// Calculate highlight styles for squares
const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
  const squareColor = (curr.charCodeAt(0) - 'a'.charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0 ? lightSquare : darkSquare;
  const highlightColor = squareColor === lightSquare ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'; // Use semi-transparent white and black

  if (hasPiece(curr)) {
    // Highlight corners for squares with pieces
    return {
      ...acc,
      [curr]: {
        background: `
          linear-gradient(to bottom right, ${highlightColor} 30%, transparent 30%) 0 0,
          linear-gradient(to bottom left, ${highlightColor} 30%, transparent 30%) 100% 0,
          linear-gradient(to top left, ${highlightColor} 30%, transparent 30%) 100% 100%,
          linear-gradient(to top right, ${highlightColor} 30%, transparent 30%) 0 100%
        `,
        backgroundSize: "40% 40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
      },
    };
  } else {
    // Highlight with a circle for empty squares
    return {
      ...acc,
      [curr]: {
        background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
        borderRadius: "50%",
      },
    };
  }
}, {});

    // Update state to select the piece and highlight possible moves
    this.setState({
      pieceSquare: square,
      squareStyles: { ...squareStyling({ pieceSquare: square, history: game.history({ verbose: true }) }), ...highlightStyles },
      highlightedSquares: [] // Clear any right-click highlights
    });
  } else {
    // Deselect the piece if another square is clicked or if it's an illegal move
    this.setState({
      pieceSquare: "",
      squareStyles: { ...squareStyling({ history: game.history({ verbose: true }) }) },
      highlightedSquares: [] // Clear any right-click highlights
    });
  }

      // Deselect the piece if the same square is clicked again
    if (square === this.state.pieceSquare) {
        this.setState({
            pieceSquare: "",
            squareStyles: { ...squareStyling({ pieceSquare: square, history: game.history({ verbose: true }) }) },
            highlightedSquares: [] // Clear any right-click highlights
        });
        return; // Exit the function to prevent further processing
    }

  // If there's a piece selected and it's a different square, attempt the move
  if (this.state.pieceSquare && this.state.pieceSquare !== square) {
    const isLegalMove = game.moves({ verbose: true }).some(move => move.from === this.state.pieceSquare && move.to === square);

    if (isLegalMove) {

          // Determine if the move is a promotion and handle accordingly
      const isPromotion = this.isPromotionMove(this.state.pieceSquare, square, piece);
    //  let promotion = 'q'; // Default promotion to queen for simplicity
      if (isPromotion) {
        this.setState({isPromotionOpen: true, promotionDetails: { sourceSquare: this.state.pieceSquare, targetSquare: square } });
      }
      else {
        this.handleMove(this.state.pieceSquare, square, this.state.promotion)
      }
    }
  }
};

//onSquareRightClick = square => {
//  let { highlightedSquares, squareStyles } = this.state;
//
//  // Ensure highlightedSquares is an array
//  if (!Array.isArray(highlightedSquares)) {
//    highlightedSquares = [];
//  }
//
//  const squareIndex = highlightedSquares.indexOf(square);
//  let newHighlightedSquares;
//  let newSquareStyles = { ...squareStyles };
//
//  if (squareIndex === -1) {
//    // If the square isn't already highlighted, add it
//    newHighlightedSquares = [...highlightedSquares, square];
//    newSquareStyles[square] = { backgroundColor: "rgba(144, 238, 144, 0.5)" }; // Highlight the square
//  } else {
//    // If the square is already highlighted, remove the highlight
//    newHighlightedSquares = highlightedSquares.filter(s => s !== square);
//    delete newSquareStyles[square]; // Remove specific square's style
//  }
//
//  this.setState({
//    highlightedSquares: newHighlightedSquares,
//    squareStyles: newSquareStyles
//  });
//};




updateCheckHighlight = () => {
  let squareStyles = {};

  if (game.inCheck()) {
    // The king is in check, find the king's position
    const board = game.board();
    let kingPosition = null;
//    console.log('CHECK!')

    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].length; j++) {
        const piece = board[i][j];
        if (piece && piece.type === 'k' && piece.color === game.turn()) {
          // Chess.js uses 0-indexed arrays, so add 1 to match algebraic notation
          const row = '87654321'[i];
          const col = 'abcdefgh'[j];
          kingPosition = col + row;
          break;
        }
      }
      if (kingPosition) break;
    }

    if (kingPosition) {
      // Apply a red circle highlight style to the king's position
      squareStyles[kingPosition] = {
        background: 'radial-gradient(circle at center, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 0) 80%)'
      };
    }
  }

  return squareStyles
};

handleUserMove = (e) => {
  e.preventDefault(); // Prevent form submission from reloading the page

   if (this.state.gameOver) {
     return;
  }

  // If the quiz is being shown, update the quiz answer with the user input
  if (this.props.showQuiz) {
    const userInput = this.state.userInputMove.trim();
    this.props.updateQuizAnswer(userInput);
    this.setState({ userInputMove: "" }); // Optionally clear the input field after updating
    return; // Exit the function early
  }

  if (!this.state.isViewingCurrentPosition) {
    console.log('Not current position')
    // Prevent making moves if not viewing the current position
    return;
  }

  const userInputMove = this.state.userInputMove.trim();
  const legalMoves = game.moves({ verbose: true }); // Generate all legal moves in verbose mode to access SAN notation
  const isLegalMove = legalMoves.some(move => move.san === userInputMove); // Check if the user's move is legal

  if (isLegalMove) {
    const result = game.move(userInputMove, { sloppy: true });
    if (result) {

      // Logic for updating state after a successful move
      const newSquareStyles = squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) });
      const checkHighlightStyles = this.updateCheckHighlight(); // Get styles for highlighting check
      const combinedSquareStyles = { ...newSquareStyles, ...checkHighlightStyles }; // Combine styles

      this.setState({
        fen: game.fen(),
        history: game.history({ verbose: true }),
        userInputMove: "", // Clear the input field
        selectedMoveIndex: game.history().length - 1,
        squareStyles: combinedSquareStyles
      }, () => {
        this.announceResult();
        this.props.updateHistory(game.history());
        this.props.updateFen(this.state.fen);
//        this.engineGame().prepareMove(); // Prepare the next move
        this.engineGameInstance.prepareMove();
      });
    } else {
      // Handle unexpected error if a legal move could not be executed
      this.flashIllegalMove();
    }
  } else {
    // Handle case where the move is not legal
    this.flashIllegalMove();
    this.setState({ userInputMove: "" }); // Optionally clear the input field if the move is illegal
  }
};


handleBackspace = () => {
  this.setState(prevState => ({
    userInputMove: prevState.userInputMove.slice(0, -1) // Removes the last character
  }));
};

handleInputChange = (event) => {
  const allowedChars = "RNBQK+=xabcdefgh12345678#O-"; // Include both uppercase and lowercase for simplicity
  const value = event.target.value;
  let formattedInput = "";

  // Iterate through each character in the input
  for (let char of value) {
    // Capitalize lowercase r, n, b, q, k
    if ("rnqko".includes(char)) {
      formattedInput += char.toUpperCase();
    }
    // Lowercase uppercase A, B, C, D, E, F, G, H
    else if ("ACDEFGH".includes(char)) {
      formattedInput += char.toLowerCase();
    }
    // Keep other allowed characters as they are
    else if (allowedChars.includes(char)) {
      formattedInput += char;
    }
    // Ignore characters not in the allowed list
  }

  // Update the state with the formatted input
  this.setState({ userInputMove: formattedInput });
};


  flashIllegalMove = () => {
  // Define a style that applies a red tint to all squares
  const illegalMoveStyles = Object.fromEntries(
    Array.from({ length: 64 }).map((_, index) => {
      const file = 'abcdefgh'[index % 8];
      const rank = 8 - Math.floor(index / 8);
      const square = `${file}${rank}`;
      return [square, { backgroundColor: 'rgba(255, 0, 0, 0.3)' }];
    })
  );

  // Temporarily set all squares to indicate an illegal move
  this.setState({
    squareStyles: illegalMoveStyles
  });

  // Remove the flash after a short delay
  setTimeout(() => {
    this.setState({
      squareStyles: squareStyling({ pieceSquare: "", history: this.state.history }), // Reset to the original square styles based on the current game state
    });
  }, 500); // Adjust timing as needed
};

handleEngineOutput = (line, isHint = false) => {
//    console.log('Engine output: ' + line);
    if (line.startsWith('bestmove')) {
        const match = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
        if (match) {
            const from = match[1], to = match[2];
            if (!isHint) {
                const move = game.move({ from, to, promotion: match[3] });
                if (move) {
                    this.updateGameStateAfterMove(from, to);
                }
            } else {
                this.highlightBestMove(from, to); // Function to highlight best move for hints
            }
        }
    }
};

highlightBestMove(from, to) {
    console.log(`Hint: best move from ${from} to ${to}`);

    // Generate base styles from game history
    const baseStyles = squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) });

    // Get additional styles from check conditions or other game-specific highlights
    const checkHighlights = this.updateCheckHighlight();

    // Prepare new square styles combining base styles, check highlights, and move highlights
    const newSquareStyles = {
        ...baseStyles,
        ...checkHighlights,
        [from]: { ...baseStyles[from], backgroundColor: 'rgba(255, 215, 0, 0.7)' }, // Merge existing styles with new highlight color
        [to]: { ...baseStyles[to], backgroundColor: 'rgba(255, 215, 0, 0.7)' }       // Merge existing styles with new highlight color
    };

    // Update the state with new styles
    this.setState({
        squareStyles: newSquareStyles
    });
}

updateGameStateAfterMove(from, to) {
//    console.log(`Move made by engine: ${from}-${to}`);
    this.setState({
        fen: game.fen(),
        history: game.history({ verbose: true }),
        pieceSquare: "",
        selectedMoveIndex: game.history().length - 1,
    }, () => {
//        console.log('History from engine: ' + this.state.history.map(move => move.san).join(', '));
        this.announceResult();
        this.props.updateHistory(game.history());
        this.props.updateFen(this.state.fen);
        setTimeout(() => {
            const newSquareStyles = { ...squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) }), ...this.updateCheckHighlight() };
            this.setState({ squareStyles: newSquareStyles });
        }, 500);
    });
}

engineGame = options => {
    options = options || {};


if (typeof SharedArrayBuffer !== 'undefined') {
    console.log("SharedArrayBuffer is supported!");
    const arrayBuffer = true
} else {
    console.log("SharedArrayBuffer is not supported. Check your server headers!");
    const arrayBuffer = false
}

let engineScript; // Declare the variable outside the if/else scope

// Checking if SharedArrayBuffer is supported
if (this.state.endgame) {
    engineScript = typeof SharedArrayBuffer !== 'undefined' ? 'stockfish-nnue-16.wasm' : 'stockfish-nnue-16-single.js';
    console.log('Loading Stockfish 16');
} else {
    engineScript = 'stockfish.js';
    console.log('Loading Stockfish 8');
}
// You can now use `engineScript` here and it will have the expected value

// Creating the engine worker with the selected script
const engine = typeof STOCKFISH === "function" ? STOCKFISH() : new Worker(options.stockfishjs || engineScript);

    let engineStatus = {
        engineReady: false,
        search: null,
        isHintMode: false // Add a flag to track hint mode
    };

    let time = { wtime: 3000, btime: 3000, winc: 1500, binc: 1500 };
    let playerColor = this.props.playerColor;

    // Helper function to send commands to the engine
    function uciCmd(cmd) {
        engine.postMessage(cmd);
    }

    // Function to get all moves from game history
    function get_moves() {
        let moves = "";
        let history = game.history({ verbose: true });
        for (let i = 0; i < history.length; ++i) {
            let move = history[i];
            moves += ` ${move.from}${move.to}${move.promotion || ''}`;
        }
        return moves;
    }

    // Event listeners for the engine
    engine.onmessage = event => {
//        console.log('in engine on message');
        const line = event.data;
        console.log('Engine:', line);

        if (line === "uciok") {
            console.log("Engine loaded.");
//            uciCmd('setoption name EvalFile value /nn-5af11540bbfe.nnue');
//            uciCmd('setoption name Use NNUE value true');
            const pathToSyzygy = '/syzygy-tables';  // Adjust the path as necessary
            if (this.state.endgame) {
                uciCmd('setoption name SyzygyPath value ' + pathToSyzygy);
                uciCmd("setoption name Threads value 4"); // Use 4 threads for calculation
                uciCmd("setoption name Hash value 256"); // Set hash size to 1024 MB
            }
            uciCmd('ucinewgame');
            uciCmd('isready');
            engineStatus.engineLoaded = true;

        } else if (line === "readyok") {
            console.log("Engine ready.");
            engineStatus.engineReady = true;
            changeSkillLevel(this.props.skillLevel);
        } else {
            this.handleEngineOutput(event.data, engineStatus.isHintMode);
        }
    };

function changeSkillLevel(skillLevel) {
    let errProb, maxErr;

    // Calculate error probability and maximum error based on skill level
    if (skillLevel <= 4) {
        errProb = Math.max(1, Math.round(-10 + (skillLevel * 10))); // More aggressive decrease for lower skill levels
        maxErr = Math.max(0, Math.round(5700 - (skillLevel * 700))); // Larger errors allowed for lower skills
    } else if (skillLevel <= 8) {
        errProb = Math.max(1, Math.round(5 + (skillLevel * 7))); // Moderate decrease for mid skill levels
        maxErr = Math.max(0, Math.round(3700 - (skillLevel * 300))); // Moderate errors allowed
    } else if (skillLevel <= 14) {
        errProb = Math.max(1, Math.round(45 + (skillLevel * 5))); // Moderate decrease for mid skill levels
        maxErr = Math.max(0, Math.round(2100 - (skillLevel * 100))); // Moderate errors allowed
    } else if (skillLevel <= 19) {
        errProb = Math.max(1, Math.round(500 + (skillLevel * 25))); // Less decrease for higher skill levels
        maxErr = Math.max(0, Math.round(1000 - (skillLevel * 50))); // Smaller errors allowed
    } else {
        errProb = 1000;
        maxErr = 0;
    }

//    console.log(`Setting skill level: ${skillLevel}, Error Probability: ${errProb}, Maximum Error: ${maxErr}`);

    // Send commands to the engine to set skill level, error probability, and maximum error
    uciCmd(`setoption name Skill Level value ${skillLevel}`);
    uciCmd(`setoption name Skill Level Maximum Error value ${maxErr}`);
    uciCmd(`setoption name Skill Level Probability value ${errProb}`);

}

    // Starting the engine, setting up game
    function start() {
        uciCmd("uci");
        console.log('Starting engine...');
//        uciCmd('ucinewgame');
//        uciCmd("isready");
//        if(engineStatus.engineReady) {
//            changeSkillLevel(0);
//            console.log('skill level changed');
        }

function getMoves() {
    let history = game.history({ verbose: true });
    return history.map(move => move.from + move.to + (move.promotion || '')).join(' ');
}

const prepareMove = (isHint = false) => {
    engineStatus.isHintMode = isHint;
//    console.log('in prepare move is hint? ' + engineStatus.isHintMode)
    const currentFen = game.fen();
//    console.log('Current FEN: ' + currentFen);
    const moves = getMoves();
    uciCmd("position fen " + this.state.initialFen + ' moves ' + moves);
    // Check if it's the player's turn based on the FEN
    const playerTurn = game.turn() === 'w' ? 'white' : 'black';

    if (playerTurn === playerColor && !isHint) {
//        console.log("Player's turn to move");
        return; // Exit early if it's the player's turn
    }

    if (!isHint) {
        changeSkillLevel(this.props.skillLevel);
        if (openingBook[currentFen] && playerTurn !== playerColor) {
        const possibleMoves = openingBook[currentFen];
        const selectedMove = possibleMoves[Math.floor(Math.random() * possibleMoves.length)]; // Randomly select a move

        const move = game.move(selectedMove, {sloppy: true}); // Attempt to make the move on the board
        if (move) {
            // Highlight the book move
            const newSquareStyles = {
                ...squareStyling({ pieceSquare: "", history: game.history({ verbose: true }) }),
                [move.from]: { backgroundColor: "rgba(255, 255, 0, 0.4)" }, // Color for the square from which the piece moved
                [move.to]: { backgroundColor: "rgba(255, 255, 0, 0.4)" } // Color for the square to which the piece moved
            };

            // Update the state with the new game position and highlight the move
            this.setState({
                fen: game.fen(),
                history: game.history({ verbose: true }),
                squareStyles: newSquareStyles,
                selectedMoveIndex: game.history().length - 1,
            }, () => {
                this.props.updateHistory(game.history());
                this.props.updateFen(this.state.fen);
//                console.log("Book move: " + selectedMove);
            });

            return; // Exit after making a book move
        }
    }

//    console.log("No book move available or book move failed, asking engine to calculate.");

//        console.log("No hint requested, engine will make a move.");
        if (this.state.endgame) {
            uciCmd("go nodes 500000"); // Search 1,000,000 nodes
        } else {
            uciCmd('go')
        }
    } else {

        changeSkillLevel(20);
//        console.log('Skill level set to 20');
//        console.log("Hint requested, engine will suggest a move.");
        if (this.state.endgame) {
            uciCmd("go nodes 500000"); // Search 1,000,000 nodes
        } else {
            uciCmd('go')
        }    }
};

    const terminate = () => {
        engine.terminate(); // Terminate the web worker running the chess engine
//        console.log('Engine terminated');
        engineStatus = {
            engineReady: false,
            search: null
        }; // Reset engine status
    };


    return { start, prepareMove, terminate };
};

announceResult = () => {
  let message = "Game is ongoing.";
  let gameOver = false;
  let result = '';

  if (this.state.hasResigned) {
    const winner = this.props.playerColor === "white" ? "Black" : "White";
    message = `${winner} wins by resignation!`;
    result = this.props.playerColor === "white" ? "0-1" : "1-0";
    gameOver = true;
  } else if (game.isCheckmate()) {
    // Checkmate
    const winner = game.turn() === "b" ? "White" : "Black";
    message = `Checkmate. ${winner} wins!`;
    result = game.turn() === "b" ? "0-1" : "1-0";
    gameOver = true;
  } else if (game.isStalemate()) {
    // Draw or Stalemate
    message = "Game is a draw (stalemate).";
    result = "1/2-1/2";
    gameOver = true;
  } else if (game.isThreefoldRepetition()) {
    // Threefold repetition
    message = "Game is a draw (threefold repetition).";
    result = "1/2-1/2";
    gameOver = true;
  } else if (game.isInsufficientMaterial()) {
    // Insufficient material
    message = "Game is a draw (insufficient material).";
    result = "1/2-1/2";
    gameOver = true;
  } else if (game.inCheck()) {
    // Check
    const inCheck = game.turn() === "b" ? "Black" : "White";
    message = `${inCheck} is in check.`;
    // No game over, so we don't change the result or gameOver flag
  }

  // Update the state to reflect game outcome
  if (gameOver) {
    this.setState({
      gameOver: true,
      gameOverOpen: true,
      gameResultMessage: message,
      gameResult: result,
    });
    this.props.updateGameOver(gameOver);
    this.engineGameInstance.terminate();
  }
};


handleHint = () => {
    this.engineGameInstance.prepareMove(true);
}

//renderMoveHistory = () => {
//  const { history } = this.state;
//
//  return (
//    <div className="move-history-container">
//      <div className="history-controls">
//        <button onClick={() => this.goToStartPosition()}>
//          <img src={process.env.PUBLIC_URL + "/buttons/backwardAll.png"} alt="Start Position" />
//        </button>
//        <button onClick={() => this.goToPreviousMove()}>
//          <img src={process.env.PUBLIC_URL + "/buttons/backward.png"} alt="Previous Move" />
//        </button>
//        <button onClick={() => this.goToNextMove()}>
//          <img src={process.env.PUBLIC_URL + "/buttons/forward.png"} alt="Next Move" />
//        </button>
//        <button onClick={() => this.goToMove(history.length - 1)}>
//          <img src={process.env.PUBLIC_URL + "/buttons/forwardAll.png"} alt="Last Move" />
//        </button>
//      </div>
//      <div className="move-history-scroll" ref={this.moveHistoryRef}>
//        <table>
//          <tbody>
//            {history.reduce((acc, move, index) => {
//              if (index % 2 === 0) {
//                acc.push([move]);
//              } else {
//                acc[acc.length - 1].push(move);
//              }
//              return acc;
//            }, []).map((pair, pairIndex) => (
//              <tr key={pairIndex}>
//                <td className="move-number">{pairIndex + 1}</td>
//                <td onClick={() => this.goToMove(pairIndex * 2)}
//                    className={`${this.state.selectedMoveIndex === pairIndex * 2 ? 'selected ' : ''}move`}>
//                  {pair[0].san}
//                </td>
//                <td onClick={() => pair[1] && this.goToMove(pairIndex * 2 + 1)}
//                    className={`${pair[1] && this.state.selectedMoveIndex === pairIndex * 2 + 1 ? 'selected ' : ''}move`}>
//                  {pair[1] ? pair[1].san : ""}
//                </td>
//              </tr>
//            ))}
//          </tbody>
//        </table>
//      </div>
//      <div className="history-controls">
//        <button onClick={this.handleTakeBackMove}>
//          <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
//        </button>
//        <button onClick={this.handleHint}>
//          <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Take Back Move" />
//        </button>
//        <div className="share-button-container">
//          <button ref={this.shareButtonRef} onClick={() => this.setState(prevState => ({ showShareOptions: !prevState.showShareOptions }))} className="toolbar-button">
//            <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Take Back Move" />
//          </button>
//          {this.state.showShareOptions && (
//            <div ref={this.dropdownRef} className="share-options">
//              <button onClick={this.copyFenToClipboard} className="share-option">Copy FEN</button>
//              <button onClick={this.copyPgnToClipboard} className="share-option">Copy PGN</button>
//              <button onClick={this.openInLichess} className="share-option">Open in Lichess</button>
//            </div>
//          )}
//        </div>
//
//        <button onClick={this.handleResigns}>
//          <img src={process.env.PUBLIC_URL + "/buttons/resigns.png"} alt="Resign" />
//        </button>
//      </div>
//    </div>
//  );
//};

handleTakeBackMove = () => {
  // Check if it's currently the player's turn; if not, do not proceed
  if (game.turn() !== this.props.playerColor[0]) {
//    console.log("It's not your turn.");
    return; // Exit if it's not the player's turn
  }

  // Ensure there are at least two plies (a full move) in the history to take back
  if (this.state.history.length < 2) {
//    console.log("Not enough moves to take back.");
    return; // Exit if there aren't enough moves
  }

  // Remove the last two moves from the history to ensure it's the player's turn again
  const updatedHistory = this.state.history.slice(0, -2);

  // Reset the game to the start position stored in props.fen and apply all moves from the updated history
  const startFen = this.state.initialFen || game.defaultFen(); // Use a default FEN if props.fen is not provided
  game.load(startFen); // Load the starting FEN
  updatedHistory.forEach(move => {
    game.move(move.san);
  });

  // Update the state with the new game state
  this.setState({
    fen: game.fen(), // Update the FEN to the new position
    history: updatedHistory, // Update the history to reflect the taken back moves
    squareStyles: squareStyling({ pieceSquare: "", history: updatedHistory }),
    isViewingCurrentPosition: true, // Allow the player to make a move in the new position
    selectedMoveIndex: updatedHistory.length - 1, // Update the selected move index
    gameOver: false,
  }, () => {
    // Optional: any additional actions to be performed after updating the state
//    console.log("Move taken back. New position is ready for a move.");
  });
};

handleResigns = () => {
  const isConfirmed = window.confirm("Are you sure you want to resign?");
  if (isConfirmed) {
    // Determine the result based on player color
    const result = this.props.playerColor === "white" ? "0-1" : "1-0";
    // Set state to indicate the player's resignation, update the game result, and then check the game result
    this.setState({ hasResigned: true, gameOver: true, gameOverOpen: true, gameResult: result }, this.announceResult);
  }
};


//goToStartPosition = () => {
////  console.log('props fen: ' + this.state.initialFen);
//  this.setState({
//    displayFen: this.state.initialFen,
//    selectedMoveIndex: -1, // Or any logic you use to denote the start position
//  });
//  // Additional logic to reset the game state as needed
//};

//goToPreviousMove = () => {
//  // If no moves have been made (e.g., selectedMoveIndex is -1 for game start), do nothing
////  console.log('selected move index: ' + this.state.selectedMoveIndex);
//  if (this.state.selectedMoveIndex <= 0) {
//    // Optionally, you can ensure the game is visually set to the start position here,
//    // but without calling goToStartPosition() to avoid redundant updates
//    if (this.state.selectedMoveIndex === 0) {
//      this.goToStartPosition();
//    }
//    // Else, do nothing for -1, indicating we're at the start of the game
//  } else {
//    // Proceed to the previous move
//    const newMoveIndex = this.state.selectedMoveIndex - 1;
//    this.goToMove(newMoveIndex);
//  }
//};



//goToNextMove = () => {
//  const newMoveIndex = Math.min(this.state.selectedMoveIndex + 1, this.state.history.length - 1);
//  this.goToMove(newMoveIndex);
//};


//goToMove = (moveIndex) => {
//  const { history } = this.state;
////  console.log('History: ' + JSON.stringify(this.state.history));
////  console.log("Initial FEN for loaded game:", this.state.initialFen);
//
//  // Start by loading the provided FEN or the default start position
//  const temporaryGame = new Chess(this.state.initialFen);
//
//  // Apply moves up to the specified index
//  history.slice(0, moveIndex + 1).forEach(move => {
//    temporaryGame.move(move.san);
//  });
//
//  // Get the FEN of the temporary game after replaying the moves
//  const tempFen = temporaryGame.fen();
//
//  // Assuming squareStyling function calculates styles based on game history
//  const updatedHistory = temporaryGame.history({ verbose: true });
//  const newSquareStyles = squareStyling({ pieceSquare: "", history: updatedHistory });
//
//  // If the temporary FEN matches the current game's FEN, call returnToCurrentPosition
//  // This checks if the player has navigated to the current position
//  if (tempFen === this.state.fen) {
//    // Call returnToCurrentPosition to reset to the current game state
//    this.returnToCurrentPosition(moveIndex);
//  } else {
//    // Update the state to reflect the temporary game's position and styles
//    this.setState({
//      displayFen: tempFen,
//      squareStyles: newSquareStyles,
//      isViewingCurrentPosition: false, // Player is viewing a past position
//      selectedMoveIndex: moveIndex,
//    });
//  }
//};

// Ensure the returnToCurrentPosition method resets the state appropriately and optionally updates visuals
//returnToCurrentPosition = (moveIndex) => {
//  // Always reset displayFen to show the current game position
//  // and indicate that we're viewing the current position
//  this.setState({
//    displayFen: null,
//    isViewingCurrentPosition: true,
//    selectedMoveIndex: moveIndex,
//  }, this.updateGameVisuals); // Optionally update the game visuals to reflect the current position
//};


// The updateGameVisuals method would update the square styles based on the current game state
//updateGameVisuals = () => {
//  // Implement logic to update the square styles and other game visuals
//  const newSquareStyles = squareStyling({ pieceSquare: "", history: this.state.history });
//  this.setState({
//    squareStyles: newSquareStyles,
//  });
//};

  toggleKeyboardButtons = () => {
    this.setState(prevState => ({
      showKeyboardButtons: !prevState.showKeyboardButtons,
    }));
  };

    // Function to handle button click
  handleButtonClick = (char) => {
    this.setState(prevState => {
      let newInputMove = prevState.userInputMove + char;
      newInputMove = newInputMove.replace(/^undefined/, '');
      return { userInputMove: newInputMove };
    });
  };

render() {
  const { squareStyles } = this.state;

  // Adjust the buttons for each character, including "#" in the first row
  const buttonsRow1 = ['R', 'N', 'B', 'Q', 'K', 'x', 'O', '-'];
  const buttonsRow2 = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
  const buttonsRow3 = ['1', '2', '3', '4', '5', '6', '7', '8'];

return (
  <div className="game-layout">
    <div className="captures-container">
      {this.renderCapturedPieces()}
    </div>
    <div className="chessboard-container">
      {this.props.children({
        position: this.state.displayFen || this.state.fen,
        history: this.state.history,
        onDrop: this.onDrop,
        squareStyles: squareStyles,
        onSquareClick: this.onSquareClick,
        onSquareRightClick: this.onSquareRightClick,
      })}
    </div>
    {/* Your chessboard and other UI elements */}
    {this.state.isPromotionOpen && (
      <div className="promotion-modal-container">
        <PromotionModal
          onPromote={(piece) => this.handlePromotion(piece)}
          pieces={['q', 'r', 'n', 'b']}
          color={this.props.playerColor === 'white' ? 'w' : 'b'}
        />
      </div>
    )}
    <div className="move-history-container">
      {renderMoveHistory(this, this.state.history, squareStyling)}
    </div>
    <div className="move-input-container">
      <form onSubmit={this.handleUserMove} className="input-form">
        <input
          type="text"
          value={this.state.userInputMove}
          onChange={this.handleInputChange}
          placeholder="Enter move in algebraic notation (e.g., Nf3)"
          className="move-input"
        />
        <button type="submit" className="submit-move-button">Submit Move</button>
      </form>
    </div>
    <GameOverModal
      isOpen={this.state.gameOverOpen}
      winner={this.state.gameResultMessage}
      onClose={() => this.setState({ gameOverOpen: false })}
    />
  </div>
);
}


}

Stockfish.propTypes = {
  children: PropTypes.func.isRequired,
  skillLevel: PropTypes.number,
  playerColor: PropTypes.string,
  userInputMove: PropTypes.string,
  highlightedSquares: PropTypes.array,
  squareStyles: PropTypes.object,
  selectedMoveIndex: PropTypes.number
};

Stockfish.defaultProps = {
  updateHistory: () => {},
  updateFen: () => {},
  updateGameOver: () => {},

};

export default Stockfish;

const squareStyling = ({ pieceSquare, history }) => {
  const sourceSquare = history.length && history[history.length - 1].from;
  const targetSquare = history.length && history[history.length - 1].to;

  return {
    [pieceSquare]: { backgroundColor: "rgba(255, 255, 0, 0.4)" }, // color when clicked
    ...(history.length && {
      [sourceSquare]: {
        backgroundColor: "rgba(255, 255, 0, 0.3)" // moved from square
      }
    }),
    ...(history.length && {
      [targetSquare]: {
        backgroundColor: "rgba(255, 255, 0, 0.5)" // moved to square
      }
    })
  };
};