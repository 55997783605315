import React, { Component } from 'react';
import StockFish from '../Integrations/Stockfish';
import ChessboardCustom from '../components/ChessboardCustom'; // Import your custom component
import '../styles/styles.css';
import QuizComponent from '../Integrations/QuizComponent';
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";

const db = getFirestore();
const gamesCollection = collection(db, "games");
const newGameRef = doc(gamesCollection);

const saveGameState = async (userId, gameId, gameState, options) => {
  await setDoc(doc(db, "games", gameId), {
    gameId: userId,
    fen: gameState.fen,
    history: gameState.history,
    updatedAt: new Date(),
    stockfishLevel: options.stockfishLevel, // Stockfish difficulty level
    blindfold: options.blindfold, // Blindfold level
    playerColor: options.playerColor, // Player color ('white' or 'black')
    quizzesEnabled: options.quizzesEnabled // Whether quizzes are on or off
  });
};

class Play extends Component {
  constructor(props) {
  super(props);
  this.state = {
    gameId: null,
    isSettingsOpen: true, // Settings popup is initially open
    playerColor: 'white', // Default player color
    difficulty: 1200, // Default difficulty level
    degreeOfBlindness: 'Normal', // Default to no blindness
    activeColor: 'white',
    activeDegree: 'Normal',
    currentFen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    history: [],
    chessboardSize: 600,
    boardAwarenessQuiz: 'no',
    showQuiz: false,
    quizAnswer: '',
    gameOver: false,
  };
  this.handleQuizOptionChange = this.handleQuizOptionChange.bind(this);
}
componentDidMount() {
  const selectedGame = JSON.parse(localStorage.getItem('selectedGame'));
  if (selectedGame) {
    console.log("Loaded game:", selectedGame);
    // Convert history from SAN array to verbose objects if necessary
    // This depends on how you're using the history in your component.
    // If you're just displaying the moves, keeping them as SAN strings might be sufficient.
    // Otherwise, you might need to initialize a Chess.js instance and replay the moves to get verbose objects.
    this.setState({
      gameId: selectedGame.gameId,
      currentFen: selectedGame.fen,
      history: selectedGame.history,
      playerColor: selectedGame.playerColor,
      difficulty: selectedGame.stockfishLevel,
      degreeOfBlindness: selectedGame.blindfold,
      boardAwarenessQuiz: selectedGame.quizzesEnabled,
      isSettingsOpen: false,
    });
  } else {
//    console.log("No game data to load.");
    // You can set default values here or just keep the initial state as defined
  }

  // Consider clearing the selected game from local storage if it's no longer needed
  localStorage.removeItem('selectedGame');
}

componentDidUpdate(prevProps, prevState) {
  if (this.state.isSettingsOpen !== prevState.isSettingsOpen) {
    document.body.style.overflow = this.state.isSettingsOpen ? 'hidden' : 'auto';
  }
}


handleQuizOptionChange(event) {
  this.setState({ boardAwarenessQuiz: event.target.value });
  console.log('Quiz on? ' + this.state.boardAwarenessQuiz)
}


goToLoadGamePage = () => {
  this.props.navigate('/loadgame');
};

  updateChessboardSize = (newSize) => {
    this.setState({ chessboardSize: newSize });
    console.log('chessboard size in play: ' + this.state.chessboardSize);
  };

  updateShowQuiz = (quiz) => {
    this.setState({ showQuiz: quiz });
  };

  updateQuizAnswer = (answer) => {
    this.setState({ quizAnswer: answer });
  };

updateGameOver = (isGameOver) => {
  this.setState({ gameOver: isGameOver }, () => {
  console.log('Game over changed to: ' + this.state.gameOver);
  });
};

updateHistory = (newHistory) => {
  this.setState({ history: newHistory }, () => {
    // This function gets called after the state is updated, ensuring synchronicity
//    console.log('Play.js history updated to:', this.state.history);
    // Perform other actions that depend on the updated state here
  });
};

updateFen = (newFen) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();

//  if (!user) {
////    console.log("No user signed in");
//    return;
//  }

  // Get the current state and check if gameId exists, create one if it doesn't
  let { gameId } = this.state;
  if (!gameId) {
    const gamesCollection = collection(db, "games");
    const newGameRef = doc(gamesCollection);
    gameId = newGameRef.id; // This will be used in the state update callback
  }

  // Use callback form to ensure `setDoc` is called after the state is updated
  this.setState((prevState) => ({
    currentFen: newFen,
    gameId: prevState.gameId || gameId, // Keep existing gameId or use new one if it was just created
    // Include other state updates if needed, e.g., history
  }), async () => { // This function will be called after the state update
    const gameRef = doc(db, "games", this.state.gameId); // Reference to the specific game document

    const gameState = {
      gameId: this.state.gameId,
      fen: this.state.currentFen,
      history: this.state.history,
      updatedAt: new Date(),
      stockfishLevel: this.state.difficulty,
      blindfold: this.state.degreeOfBlindness,
      playerColor: this.state.playerColor,
      quizzesEnabled: this.state.boardAwarenessQuiz,
      gameOver: this.state.gameOver,
    };

    try {
      await setDoc(gameRef, gameState); // This will create a new doc or update an existing one
      console.log("Game state saved successfully");
    } catch (error) {
      console.error("Failed to save game state", error);
    }
  });
};


handleColorChange = (selectedColor) => {
    // If the selected color is "random", choose between 'white' or 'black' randomly.
    if (selectedColor === "random") {
        const randomColor = Math.random() < 0.5 ? 'white' : 'black';
        // Set the playerColor state to the randomly chosen color.
        this.setState({ activeColor: selectedColor, playerColor: randomColor }, () => {
            // This callback function will execute after state is updated.
//            console.log('Randomly chose: ' + this.state.playerColor);
        });
    } else {
        // Directly use the selectedColor to update playerColor.
        this.setState({ activeColor: selectedColor, playerColor: selectedColor });
    }
};

getButtonClass = (color) => {
  return `image-button ${this.state.activeColor === color ? 'active' : ''}`;
};

  handleDifficultyChange = (event) => {
    this.setState({ difficulty: event.target.value });
  };

  handleDegreeOfBlindnessChange = (selectedDegree) => {
    this.setState({ activeDegree: selectedDegree, degreeOfBlindness: selectedDegree });
  };

getButtonClass2 = (degree) => {
  return `image-button ${this.state.activeDegree === degree ? 'active' : ''}`;
};

  startGame = () => {
    this.setState({ isSettingsOpen: false });
    // Additional initialization logic can be added here if needed
  };



render() {
  const { isSettingsOpen, dropdownValue, playerColor, difficulty, degreeOfBlindness, currentFen, chessboardSize } = this.state;


return (
  <div>
    {isSettingsOpen ? (
      <div className="settings-modal">
      <div className='help-link'>
        <a href="/playblindfold/help" target="_blank" rel="noopener noreferrer">Help</a>
      </div>
        <h2>Game Settings</h2>
        {/* Color selection buttons */}
        <h3>Color:</h3>
        <div className="image-button-container">
          <button className={this.getButtonClass("white")} onClick={() => this.handleColorChange("white")}>
            <img src={process.env.PUBLIC_URL + "/pieces/normal/wK.png"} alt="White" />
          </button>
          <button className={this.getButtonClass("black")} onClick={() => this.handleColorChange("black")}>
            <img src={process.env.PUBLIC_URL + "/pieces/normal/bK.png"} alt="Black" />
          </button>
          <button className={this.getButtonClass("random")} onClick={() => this.handleColorChange("random")}>
            <img src={process.env.PUBLIC_URL + "/questionMark.png"} alt="Random" />
          </button>
        </div>
        {/* Difficulty Level settings */}
        <div className="settings-section">
          <label className="slider-label">
            Difficulty level: {difficulty}
          </label>
          <input
            type="range"
            min="0"
            max="3000"
            step="100" // This makes the slider snap to increments of 100
            value={difficulty}
            onChange={this.handleDifficultyChange}
            className="slider" // Use the custom slider class
          />
        </div>


        {/* Degree of Blindness selection buttons */}
        <h3>Blindfold Level:
            <span className="info-icon">i
                <span className="tooltip-text1">The amount of visual information which will be available to you in the game. There are six levels of difficulty offered by changing the shape and color of piece icons.</span>
            </span>
        </h3>
            <div className="image-button-container">
              <button className={this.getButtonClass2("Normal")} onClick={() => this.handleDegreeOfBlindnessChange("Normal")}>
                <img src={process.env.PUBLIC_URL + "/buttons/normal.png"} alt="Normal"/>
                <span className="tooltip-text">Normal</span>
              </button>
              <button className={this.getButtonClass2("Normal Colorless")} onClick={() => this.handleDegreeOfBlindnessChange("Normal Colorless")}>
                <img src={process.env.PUBLIC_URL + "/buttons/normalColorless.png"} alt="Normal Colorless"/>
                <span className="tooltip-text">Normal Colorless</span>
              </button>
              <button className={this.getButtonClass2("Marker")} onClick={() => this.handleDegreeOfBlindnessChange("Marker")}>
                <img src={process.env.PUBLIC_URL + "/buttons/marker.png"} alt="Marker"/>
                <span className="tooltip-text">Markers</span>
              </button>
              <button className={this.getButtonClass2("Marker Colorless")} onClick={() => this.handleDegreeOfBlindnessChange("Marker Colorless")}>
                <img src={process.env.PUBLIC_URL + "/buttons/markerColorless.png"} alt="Marker Colorless"/>
                <span className="tooltip-text">Markers Colorless</span>
              </button>
              <button className={this.getButtonClass2("Board Only")} onClick={() => this.handleDegreeOfBlindnessChange("Board Only")}>
                <img src={process.env.PUBLIC_URL + "/buttons/boardOnly.png"} alt="Board Only" className='custom-size' />
                <span className="tooltip-text">Board Only</span>
              </button>
              <button className={this.getButtonClass2("Blindfold")} onClick={() => this.handleDegreeOfBlindnessChange("Blindfold")}>
                <img src={process.env.PUBLIC_URL + "/buttons/Blindfold.png"} alt="Blindfold"/>
                <span className="tooltip-text">Blindfold</span>
              </button>
            </div>
            <div>
            <div className="quiz-options-container">
              <h3>Board Awareness Quizzes:
                <span className="info-icon">i
                    <span className="tooltip-text1">If selected, this option will randomly quiz you on all possible checks and captures in the current position of the game.</span>
                </span>
              </h3>
              <div className="radio-buttons-container">
                <label>
                  <input
                    type="radio"
                    name="boardAwarenessQuiz"
                    value="yes"
                    checked={this.state.boardAwarenessQuiz === 'yes'}
                    onChange={this.handleQuizOptionChange}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="boardAwarenessQuiz"
                    value="no"
                    checked={this.state.boardAwarenessQuiz === 'no'}
                    onChange={this.handleQuizOptionChange}
                  />
                  No
                </label>
              </div>
            </div>

            </div>
        <button onClick={this.startGame} className="btn-1">Start Game</button>
        <button onClick={this.goToLoadGamePage} className="btn-2">Load Game</button>
      </div>
      ) : (
        <StockFish skillLevel={this.calculateSkillLevel(difficulty)} fen={this.state.currentFen} gameOver={this.state.gameOver} history={this.state.history} playerColor={this.state.playerColor} updateGameOver={this.updateGameOver} updateFen={this.updateFen} updateHistory={this.updateHistory} showQuiz={this.state.showQuiz} updateQuizAnswer={this.updateQuizAnswer}>
          {({ position, onDrop, squareStyles, onSquareClick, onSquareRightClick}) => (
            <>
              <ChessboardCustom
                position={position}
                onDrop={onDrop}
                orientation={playerColor}
                squareStyles={squareStyles}
                onSquareClick={onSquareClick}
                degreeOfBlindness={degreeOfBlindness}
                onSquareRightClick={onSquareRightClick}
                updateChessboardSize={this.updateChessboardSize}
                showQuiz={this.state.showQuiz}
                updateQuizAnswer={this.updateQuizAnswer}
              />
              {this.state.boardAwarenessQuiz === 'yes' && (
                <QuizComponent
                  fen={currentFen}
                  playerColor={playerColor}
                  chessboardSize={chessboardSize}
                  updateShowQuiz={this.updateShowQuiz}
                  quizAnswer={this.state.quizAnswer}
                />
              )}
            </>
          )}
        </StockFish>
      )}
    </div>
  );
}

  calculateSkillLevel = (difficulty) => {
//    const skillLevel = 1 + (difficulty / 3000) * 19;
const skillLevel = 1 + (difficulty / 3000) * 19;
    return Math.round(skillLevel);
  };
}

export default Play;
