import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css'; // Ensure this imports your updated CSS
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler

function HomePage() {
    const navigate = useNavigate();

    // State to manage dropdown visibility
    const [showVisualizationDropdown, setShowVisualizationDropdown] = useState(false);
    const [showPatternDropdown, setShowPatternDropdown] = useState(false);

    // Toggle dropdown visibility
    const toggleVisualizationDropdown = () => {
        setShowPatternDropdown(false); // Ensure only one dropdown is open
        setShowVisualizationDropdown(prevState => !prevState);
    };
    const togglePatternDropdown = () => {
        setShowVisualizationDropdown(false); // Ensure only one dropdown is open
        setShowPatternDropdown(prevState => !prevState);
    };

    // Function to close dropdowns when clicking outside
    useEffect(() => {
        const closeDropdowns = (e) => {
            if (!e.target.closest('.home-dropdown')) {
                setShowVisualizationDropdown(false);
                setShowPatternDropdown(false);
            }
        };
        document.addEventListener('click', closeDropdowns);

        return () => {
            document.removeEventListener('click', closeDropdowns);
        };
    }, []);

    return (
        <div className="home-page">
            <EnableScrollOnPage />
            <div className="home-content">
                <h1 className="home-title">TRAIN CHESS</h1>
                <p className="home-subtitle">Master the game of kings</p>

                {/* Visualization button with dropdown */}
                <div className="home-btn-container">
                <div className="home-dropdown">
                    <button className="home-btn home-visualization-btn" onClick={toggleVisualizationDropdown}>
                        Visualization
                    </button>
                    {showVisualizationDropdown && (
                        <div className="home-dropdown-content">
                            <button className="home-dropdown-item" onClick={() => navigate('/play')}>Play Blindfold</button>
                            <button className="home-dropdown-item" onClick={() => navigate('/blindfoldpuzzles')}>Blindfold Puzzles</button>
                            <button className="home-dropdown-item" onClick={() => navigate('/coordinatetrainer')}>Coordinate Trainer</button>
                        </div>
                    )}
                </div>

                {/* Pattern Recognition button with dropdown */}
                <div className="home-dropdown">
                    <button className="home-btn home-pattern-btn" onClick={togglePatternDropdown}>
                        Pattern Recognition
                    </button>
                    {showPatternDropdown && (
                        <div className="home-dropdown-content">
                            <button className="home-dropdown-item" onClick={() => navigate('/puzzles')}>Puzzles</button>
                            <button className="home-dropdown-item" onClick={() => navigate('/blindfoldpuzzles')}>Blindfold Puzzles</button>
                            <button className="home-dropdown-item" onClick={() => navigate('/calculationpuzzles')}>Calculation Puzzles</button>
                            <button className="home-dropdown-item" onClick={() => navigate('/endgamepuzzles')}>Endgame Puzzles</button>
                            <button className="home-dropdown-item" onClick={() => navigate('/findthefork')}>Find the Fork</button>
                        </div>
                    )}
                </div>
                </div>

                {/* About button */}
                <button className="home-btn home-about-btn" onClick={() => navigate('/help')}>
                    Help
                </button>
            </div>
        </div>
    );
}

export default HomePage;
