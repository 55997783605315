import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { useUser } from './../UserContext'; // Adjust the import path as needed
import '../../styles/Header.css';

function MobileHeader() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVisualizationDropdownOpen, setIsVisualizationDropdownOpen] = useState(false); // Visualization dropdown state
  const [isPatternDropdownOpen, setIsPatternDropdownOpen] = useState(false); // Pattern Recognition dropdown state

  const navigate = useNavigate();
  const { username } = useUser(); // Use the username from context

  const auth = getAuth();

  // Handles user logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Redirect to login page after logout
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  // Toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Toggle the dropdowns for Visualization and Pattern Recognition
  const toggleVisualizationDropdown = () => {
    setIsVisualizationDropdownOpen(!isVisualizationDropdownOpen);
    setIsPatternDropdownOpen(false); // Close the other dropdown if open
  };

  const togglePatternDropdown = () => {
    setIsPatternDropdownOpen(!isPatternDropdownOpen);
    setIsVisualizationDropdownOpen(false); // Close the other dropdown if open
  };

  return (
    <div>
      <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
        <img src={`${process.env.PUBLIC_URL}/buttons/menu.png`} alt="Menu" />
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu-container">
          <div className="mobile-menu">
            <div className="menu-header">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="menu-logo" />
              <h1>{username}</h1>
            </div>

            <Link to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>
              Home
            </Link>

            {/* Mobile Dropdown for Visualization */}
            <div className="mobile-dropdown">
              <button className="mobile-menu-item mobile-dropdown-btn" onClick={toggleVisualizationDropdown}>
                Visualization
              </button>
              {isVisualizationDropdownOpen && (
                <div className="mobile-dropdown-content show">
                  <Link to="/play" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Play Blindfold
                  </Link>
                  <Link to="/blindfoldpuzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Blindfold Puzzles
                  </Link>
                  <Link to="/coordinatetrainer" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Coordinate Trainer
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile Dropdown for Pattern Recognition */}
            <div className="mobile-dropdown">
              <button className="mobile-menu-item mobile-dropdown-btn" onClick={togglePatternDropdown}>
                Pattern Recognition
              </button>
              {isPatternDropdownOpen && (
                <div className="mobile-dropdown-content show">
                  <Link to="/puzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Normal Puzzles
                  </Link>
                  <Link to="/blindfoldpuzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Blindfold Puzzles
                  </Link>
                  <Link to="/calculationpuzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Calculation Puzzles
                  </Link>
                  <Link to="/endgamepuzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Endgame Puzzles
                  </Link>
                  <Link to="/findthefork" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Find the Fork
                  </Link>
                </div>
              )}
            </div>

            {username && username !== 'Guest' ? (
              <>
                <Link to="/settings" className="mobile-menu-item" onClick={toggleMobileMenu}>
                  Settings
                </Link>
                <div className="mobile-menu-item" onClick={handleLogout}>
                  Log Out
                </div>
              </>
            ) : (
              <Link to="/login" className="mobile-menu-item" onClick={toggleMobileMenu}>
                Log In
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileHeader;
