import React, { useState, useEffect } from 'react';
import '../styles/fileStructure.css';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore
import { useUser } from '../Integrations/UserContext';

const db = getFirestore(); // Initialize Firestore instance

const FileStructure = ({ onFileSelect }) => {
  const [files, setFiles] = useState({});
  const [expandedFolders, setExpandedFolders] = useState({}); // Track expanded/collapsed state
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      fetchFiles();
    }
  }, [user]);

  const fetchFiles = async () => {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setFiles(data.MoveTrainer || {});
    } else {
      console.log('No such document!');
    }
  };

  const handleFileClick = (MoveTrainer) => {
    onFileSelect(MoveTrainer); // Pass selected file data to parent component
  };

  const toggleFolder = (folderName) => {
    setExpandedFolders((prevState) => ({
      ...prevState,
      [folderName]: !prevState[folderName], // Toggle expanded/collapsed state
    }));
  };

  return (
    <div className="file-structure">
      <div className="file-list">
        <h3>Opening Repertoire</h3>
        {Object.keys(files).map((folderName) => (
          <div key={folderName}>
            <h4 onClick={() => toggleFolder(folderName)} className="folder-header">
              {expandedFolders[folderName] ? '▼' : '►'} {folderName}
            </h4>
            {expandedFolders[folderName] && (
              <ul>
                {files[folderName].map((MoveTrainer, index) => (
                  <li key={index} onClick={() => handleFileClick(MoveTrainer)} className="file-item">
                    {MoveTrainer.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileStructure;
