//import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
//import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";
//import { getFirestore, doc, setDoc } from "firebase/firestore";
//import '../firebase-config';
//import '../styles/Credentials.css';
//
//const auth = getAuth();
//const db = getFirestore();
//
//export default function SignUp() {
//    const [email, setEmail] = useState('');
//    const [password, setPassword] = useState('');
//    const [confirmPassword, setConfirmPassword] = useState('');
//    const [username, setUsername] = useState('');
//    const [message, setMessage] = useState(''); // State for feedback message
//    const navigate = useNavigate();
//
//    const isPasswordValid = (password) => {
//        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
//        return regex.test(password);
//    };
//
//    const signUp = async (event) => {
//        event.preventDefault();
//
//        if (!username.trim()) {
//            setMessage("Username is required.");
//            return;
//        }
//
//        if (password !== confirmPassword) {
//            setMessage("Passwords don't match.");
//            return;
//        }
//
//        if (!isPasswordValid(password)) {
//            setMessage("Password must be at least 8 characters long and include a number and a special character.");
//            return;
//        }
//
//        try {
//            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//            const user = userCredential.user;
//            console.log('User created:', user);
//
//            await setDoc(doc(db, "users", user.uid), { username: username });
//
//            setMessage('User created successfully!');
//            navigate('/'); // Navigate to another page upon successful signup
//        } catch (error) {
//            setMessage(`Error signing up: ${error.message}`);
//        }
//    };
//
//    const googleSignUp = async () => {
//        const provider = new GoogleAuthProvider();
//        try {
//            console.log('Starting Google sign-up with redirect...');
//            await signInWithRedirect(auth, provider);
//        } catch (error) {
//            console.error('Error with Google sign-up:', error.message);
//            setMessage(error.message);
//        }
//    };
//
//useEffect(() => {
//    console.log('Checking for Google sign-in redirect result...');
//    getRedirectResult(auth)
//        .then((result) => {
//            if (result) {
//                console.log('Full redirect result object:', result);
//
//                if (result.user) {
//                    const user = result.user;
//                    const username = user.displayName || "Google User";
//
//                    // Store user info in Firestore
//                    setDoc(doc(db, "users", user.uid), { username: username });
//
//                    console.log('Google sign-up successful:', user);
//                    setMessage('Google sign-up successful!');
//                    navigate('/');  // Redirect after successful sign-up
//                } else {
//                    console.log('No user found in the redirect result.');
//                }
//            } else {
//                console.log('No redirect result returned.');
//            }
//        })
//        .catch((error) => {
//            console.error('Error with Google redirect sign-in:', error.message);
//            setMessage(error.message);
//        });
//}, [navigate]);
//
//
//    useEffect(() => {
//        if (window.google && window.google.accounts) {
//            console.log('Rendering Google Sign-Up Button...');
//            window.google.accounts.id.initialize({
//                client_id: "110322528888-udb9nk0i9ciqn1re4l8bsi46u1sfdi72.apps.googleusercontent.com", // Replace with your Google OAuth client ID
//            });
//            window.google.accounts.id.renderButton(
//                document.getElementById("google-signup-button"),
//                { theme: "outline", size: "large", text: "continue_with" }
//            );
//        } else {
//            console.error('Google Sign-In script not loaded.');
//        }
//    }, []);
//
//    return (
//        <>
//        <form onSubmit={signUp} className="signup-form">
//            <div className="signup-container">
//                <h1>Sign Up</h1>
//                <input
//                    type="text"
//                    value={username}
//                    onChange={(e) => setUsername(e.target.value)}
//                    placeholder="Username"
//                    autoComplete="username" // Added autoComplete for username
//                    required
//                />
//                <input
//                    type="email"
//                    value={email}
//                    onChange={(e) => setEmail(e.target.value)}
//                    placeholder="Email"
//                    autoComplete="email" // Added autoComplete for email
//                    required
//                />
//                <input
//                    type="password"
//                    value={password}
//                    onChange={(e) => setPassword(e.target.value)}
//                    placeholder="Password"
//                    autoComplete="new-password" // autoComplete for password creation
//                    required
//                />
//                <input
//                    type="password"
//                    value={confirmPassword}
//                    onChange={(e) => setConfirmPassword(e.target.value)}
//                    placeholder="Confirm Password"
//                    autoComplete="new-password" // autoComplete for confirming new password
//                    required
//                />
//                <button type='submit' className='btn-1'>Create Account</button>
//
//                {/* Google Official Sign-Up Button */}
//                <div id="google-signup-button" style={{ marginTop: '20px' }}></div>
//
//                <p>Already have an account? <a href="/login">Log in</a></p>
//            </div>
//        </form>
//
//        {message && <div className="error-message">{message}</div>}
//        </>
//    );
//}


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import '../firebase-config';
import '../styles/Credentials.css';

const auth = getAuth();
const db = getFirestore();

export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState(''); // State for feedback message
    const navigate = useNavigate();

    const isPasswordValid = (password) => {
        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
        return regex.test(password);
    };

    const signUp = async (event) => {
        event.preventDefault();

        if (!username.trim()) {
            setMessage("Username is required.");
            return;
        }

        if (password !== confirmPassword) {
            setMessage("Passwords don't match.");
            return;
        }

        if (!isPasswordValid(password)) {
            setMessage("Password must be at least 8 characters long and include a number and a special character.");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('User created:', user);

            await setDoc(doc(db, "users", user.uid), { username: username });

            setMessage('User created successfully!');
            navigate('/'); // Navigate to another page upon successful signup
        } catch (error) {
            setMessage(`Error signing up: ${error.message}`);
        }
    };

    const googleSignUp = async () => {
        const provider = new GoogleAuthProvider();
        try {
            console.log('Starting Google sign-up with redirect...');
            await signInWithRedirect(auth, provider);
        } catch (error) {
            console.error('Error with Google sign-up:', error.message);
            setMessage(error.message);
        }
    };

    useEffect(() => {
        console.log('Checking for Google sign-in redirect result...');
        getRedirectResult(auth)
            .then((result) => {
                if (result) {
                    console.log('Full redirect result object:', result);

                    if (result.user) {
                        const user = result.user;
                        const username = user.displayName || "Google User";

                        // Store user info in Firestore
                        setDoc(doc(db, "users", user.uid), { username: username });

                        console.log('Google sign-up successful:', user);
                        setMessage('Google sign-up successful!');
                        navigate('/');  // Redirect after successful sign-up
                    } else {
                        console.log('No user found in the redirect result.');
                    }
                } else {
                    console.log('No redirect result returned.');
                }
            })
            .catch((error) => {
                console.error('Error with Google redirect sign-in:', error.message);
                setMessage(error.message);
            });
    }, [navigate]);

    return (
        <>
        <form onSubmit={signUp} className="signup-form">
            <div className="signup-container">
                <h1>Sign Up</h1>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    autoComplete="username"
                    required
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    autoComplete="email"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    autoComplete="new-password"
                    required
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    required
                />
                <button type='submit' className='btn-1'>Create Account</button>



                <p>Already have an account? <a href="/login">Log in</a></p>
            </div>
        </form>

        {message && <div className="error-message">{message}</div>}
        </>
    );
}


//                {/* Custom Google Sign-Up Button */}
//                <button onClick={googleSignUp} className='btn-google-signup' type="button" style={{ marginTop: '20px' }}>
//                    Continue with Google
//                </button>