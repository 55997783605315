import React, { useState } from 'react';

const SaveLineModal = ({ moves, onSave, onClose, directories }) => {
  const [lineName, setLineName] = useState('');
  const [selectedDirectory, setSelectedDirectory] = useState('');
  const [newDirectory, setNewDirectory] = useState('');

  const handleSave = () => {
    const directory = newDirectory || selectedDirectory;
    if (lineName && directory) {
      onSave(lineName, directory);
    }
  };

  return (
    <div className="modal">
      <h2>Save Opening Line</h2>
      <input
        type="text"
        placeholder="Line Name"
        value={lineName}
        onChange={(e) => setLineName(e.target.value)}
      />
      <div>
        <select
          value={selectedDirectory}
          onChange={(e) => setSelectedDirectory(e.target.value)}
        >
          <option value="">Select Directory</option>
          {directories.map((dir) => (
            <option key={dir} value={dir}>
              {dir}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Or Create New Directory"
          value={newDirectory}
          onChange={(e) => setNewDirectory(e.target.value)}
        />
      </div>
      <button onClick={handleSave}>Save</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
};

export default SaveLineModal;
