import React, { useState, useEffect } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Firestore methods
import { useUser } from '../Integrations/UserContext'; // Assuming you have a user context
import { Link } from 'react-router-dom';

const db = getFirestore(); // Initialize Firestore

function FindFork() {
    const [game, setGame] = useState(new Chess());
    const [forkSquare, setForkSquare] = useState('');
    const [kingSquare, setKingSquare] = useState('');
    const [queenSquare, setQueenSquare] = useState('');
    const [knightSquare, setKnightSquare] = useState('');
    const [answerSquare, setAnswerSquare] = useState('');
    const [squareStyles, setSquareStyles] = useState({});
    const [score, setScore] = useState(0); // Track the current score
    const [isGameActive, setIsGameActive] = useState(false); // Track game state
    const [timeLeft, setTimeLeft] = useState(15); // 15-second timer
    const [bestScore, setBestScore] = useState(null); // Store the user's best score
    const [gameOverMessage, setGameOverMessage] = useState(''); // Message after the game ends
    const { user } = useUser(); // Retrieve the current user

    // Function to generate a valid knight puzzle
    const generatePuzzle = () => {
        const validSquares = [...Array(64).keys()]
            .map(i => {
                const file = 'abcdefgh'[i % 8];
                const rank = (Math.floor(i / 8) + 1).toString();
                return `${file}${rank}`;
            })
            .filter(sq => !['a1', 'a8', 'h1', 'h8'].includes(sq)); // Avoid corner squares

        // Randomly select the solution square (fork square)
        const randomForkSquare = validSquares[Math.floor(Math.random() * validSquares.length)];
        setForkSquare(randomForkSquare);

        // Get all valid knight moves from the solution square
        const knightMoves = getKnightMoves(randomForkSquare);

        // Ensure there are enough knight moves to place the pieces
        if (knightMoves.length < 3) {
            generatePuzzle(); // If not enough moves, regenerate the puzzle
            return;
        }

        // Randomly choose 3 distinct squares from the knight's moves
        const shuffledMoves = knightMoves.sort(() => 0.5 - Math.random());
        const [randomKnightSquare, randomKingSquare, randomQueenSquare] = shuffledMoves.slice(0, 3);

        // Set the king, queen, and knight positions
        setKingSquare(randomKingSquare);
        setQueenSquare(randomQueenSquare);
        setKnightSquare(randomKnightSquare);
        setAnswerSquare('');
        setSquareStyles({}); // Clear previous square styles

        // Set up the board with the king, queen, and knight
        const newGame = new Chess();
        newGame.clear();
        newGame.put({ type: 'k', color: 'b' }, randomKingSquare); // White King
        newGame.put({ type: 'q', color: 'b' }, randomQueenSquare); // White Queen
        newGame.put({ type: 'n', color: 'w' }, randomKnightSquare); // Black Knight (one knight move away)
        setGame(newGame);
    };

    // Get valid knight moves for a square
    const getKnightMoves = (square) => {
        const file = square.charCodeAt(0);
        const rank = parseInt(square[1], 10);

        const potentialMoves = [
            { fileDiff: 1, rankDiff: 2 },
            { fileDiff: 1, rankDiff: -2 },
            { fileDiff: -1, rankDiff: 2 },
            { fileDiff: -1, rankDiff: -2 },
            { fileDiff: 2, rankDiff: 1 },
            { fileDiff: 2, rankDiff: -1 },
            { fileDiff: -2, rankDiff: 1 },
            { fileDiff: -2, rankDiff: -1 },
        ];

        return potentialMoves
            .map(move => String.fromCharCode(file + move.fileDiff) + (rank + move.rankDiff))
            .filter(move => /^[a-h][1-8]$/.test(move));
    };

    // Handle square selection (user clicking on the board)
    const handleSquareClick = async (square) => {
        if (square === forkSquare) {
            await flashBoardGreen(); // Flash green on correct
            if (isGameActive) {
                setScore(prevScore => prevScore + 1); // Increment score only if in game mode
            }
            setTimeout(generatePuzzle, 50); // Generate a new puzzle
        } else {
            await flashBoardRed(); // Flash red on incorrect
        }
        setAnswerSquare(square); // Highlight the clicked square
    };

    // Flash the board green for correct answers
    const flashBoardGreen = async () => {
        const flashStyle = {
            ...generateFlashStyle("rgba(0, 255, 0, 0.5)"), // Green flash
        };

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 200)); // Flash duration
        setSquareStyles({});
    };

    // Flash the board red for incorrect answers
    const flashBoardRed = async () => {
        const flashStyle = {
            ...generateFlashStyle("rgba(255, 0, 0, 0.5)"), // Red flash
        };

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 200)); // Flash duration
        setSquareStyles({});
    };

    // Generate flash style for all squares
    const generateFlashStyle = (color) => {
        const allSquares = [];
        for (let row = 1; row <= 8; row++) {
            for (let col of 'abcdefgh') {
                allSquares.push(col + row);
            }
        }
        return allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: color };
            return styles;
        }, {});
    };

    // Start a new game
    const startGame = () => {
        setScore(0);
        setTimeLeft(15); // Reset the timer
        setIsGameActive(true); // Activate game mode
        generatePuzzle(); // Start with a new puzzle
    };

    // End the game and check if the score is a new record
    const endGame = async () => {
        setIsGameActive(false); // End game mode
        if (score > bestScore) {
            setBestScore(score);
            setGameOverMessage(`New record! Your new best score is ${score}.`);
            if (user) {
                const userRef = doc(db, "users", user.uid);
                await setDoc(userRef, { bestFindForkScore: score }, { merge: true });
            }
        } else if (score === bestScore) {
            setGameOverMessage(`You tied your best score of ${bestScore}!`);
        } else {
            setGameOverMessage(`Your score was ${score}. Best score: ${bestScore}. Keep trying!`);
        }
    };

    // Timer countdown logic
    useEffect(() => {
        if (isGameActive && timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else if (isGameActive && timeLeft === 0) {
            endGame(); // End the game when the timer reaches zero
        }
    }, [timeLeft, isGameActive]);

    // Load the user's best score from Firebase
    useEffect(() => {
        const fetchBestScore = async () => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setBestScore(userDoc.data().bestFindForkScore || 0);
                } else {
                    setBestScore(0);
                }
            }
        };

        fetchBestScore();
    }, [user]);

    // Set up the chessboard and generate the first puzzle
    useEffect(() => {
        generatePuzzle(); // Generate first puzzle on load
    }, []);

    return (
        <div className="game-layout">
            <div className="chessboard-container">
                <ChessboardCustom
                    position={game.fen()}
                    onSquareClick={handleSquareClick}
                    squareStyles={squareStyles} // Apply square styles for flash effects
                    degreeOfBlindness="Normal"
                />
            </div>
            <div className="feedback-container">
                {/* Help button at the top-right */}
                <Link to="/findthefork/help" className="help-link">
                    Help
                </Link>
                <p style={{ marginTop: '30px' }}>
                  {isGameActive ? `Time Left: ${timeLeft}s` : `Best Score: ${bestScore || 'N/A'}`}
                </p>
                {gameOverMessage && <p>{gameOverMessage}</p>}
                {!isGameActive && <button onClick={startGame} className="puzzle-button">Start Game</button>}
            </div>
        </div>
    );
}

export default FindFork;
