import React, { useState, useEffect, useRef } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useUser } from '../Integrations/UserContext';
import PromotionModal from '../Integrations/PromotionModal';
import '../styles/puzzles.css';
import QuizComponent from '../Integrations/QuizComponent';

const db = getFirestore();

function EndgamePuzzles() {
  const [currentPuzzle, setCurrentPuzzle] = useState(null);
  const [moveHistory, setMoveHistory] = useState([]);
  const [playerColor, setPlayerColor] = useState('white');
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState('');
  const [previousFen, setPreviousFen] = useState(null);
  const [squareStyles, setSquareStyles] = useState({});
  const [pieceSquare, setPieceSquare] = useState("");
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [promotionOpen, setPromotionOpen] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [answeredCorrect, setAnsweredCorrect] = useState(true);
  const { user, userDetails } = useUser();
  const [puzzleRating, setPuzzleRating] = useState(null);
  const [puzzleAccuracy, setPuzzleAccuracy] = useState(0);
  const [puzzleCount, setPuzzleCount] = useState(0);
  const [readyForMove, setReadyForMove] = useState(false);
  const [currentSquare, setCurrentSquare] = useState(null);
  const [chessboardSize, setChessboardSize] = useState(600);
  const [quizAnswer, setQuizAnswer] = useState("");
  const [boardAwarenessQuiz, setBoardAwarenessQuiz] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [loadingPuzzle, setLoadingPuzzle] = useState(false);
  const loadingPuzzleRef = useRef(false);

  const moves = currentPuzzle?.Moves.split(' ');

useEffect(() => {
  const fetchUserData = async () => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const data = userDoc.data();

        // Check if endgamePuzzleStats exists
        const endgamePuzzleStats = data.endgamePuzzleStats || null;

        if (endgamePuzzleStats) {
          // Decode endgamePuzzleStats string into individual values
          const { rating, accuracy, count } = decodeendgamePuzzleStats(endgamePuzzleStats);
          setPuzzleRating(rating);
          setPuzzleAccuracy(accuracy);
          setPuzzleCount(count);
        } else {
          // If no endgamePuzzleStats, initialize with default values
          const initialStats = "r1000a0c0"; // Rating: 1000, Accuracy: 0%, Puzzle Count: 0
          await setDoc(userRef, { endgamePuzzleStats: initialStats }, { merge: true });

          setPuzzleRating(1000);
          setPuzzleAccuracy(0);
          setPuzzleCount(0);
        }

        setShowLoginMessage(false);
      } else {
        // If no userDoc exists, create a new one with default puzzle stats
        const initialStats = "r1000a0c0"; // Rating: 1000, Accuracy: 0%, Puzzle Count: 0
        await setDoc(userRef, { endgamePuzzleStats: initialStats }, { merge: true });

        setPuzzleRating(1000);
        setPuzzleAccuracy(0);
        setPuzzleCount(0);
        setShowLoginMessage(false);
      }
    } else {
      setPuzzleRating(1000);
      setShowLoginMessage(true);
    }
  };

  fetchUserData();
}, [user]);

// Helper function to decode endgamePuzzleStats string into individual values
const decodeendgamePuzzleStats = (endgamePuzzleStats) => {
  const rating = parseInt(endgamePuzzleStats.match(/r(\d+)/)[1], 10); // Extract rating
  const accuracy = parseFloat(endgamePuzzleStats.match(/a([\d.]+)/)[1]); // Extract accuracy
  const count = parseInt(endgamePuzzleStats.match(/c(\d+)/)[1], 10); // Extract puzzle count

  return { rating, accuracy, count };
};

useEffect(() => {
  let timer; // To hold the timeout

  const fetchPuzzle = async () => {
    if (puzzleRating === undefined || loadingPuzzleRef.current) return; // Check the ref instead of state

    loadingPuzzleRef.current = true; // Set ref to true immediately
    setLoadingPuzzle(true); // This will still trigger a re-render, but the ref is immediate

    try {
      const chunkNumber = Math.floor(Math.random() * 722) + 1; // Adjust for calculation puzzles
      const url = `${process.env.PUBLIC_URL}/calculationpuzzles/puzzles_chunk_${chunkNumber}.json`;
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const puzzles = await response.json();

      // Filter puzzles by rating range, number of pieces, and endgame status
      const filteredPuzzles = puzzles.filter(puzzle => {
        const pieceCount = puzzle.FEN.split(' ')[0]
          .replace(/\//g, '')   // Remove rank separators ("/")
          .replace(/\d/g, '')   // Remove empty square counts (digits)
          .length;              // Count remaining characters (which represent pieces)

        // Endgame filter: usually 10 or fewer pieces on the board
        const isEndgame = pieceCount <= 10;

        // Return only puzzles that match the rating range and are in endgame
        return Math.abs(puzzle.Rating - puzzleRating) <= 50 && isEndgame;
      });

      if (filteredPuzzles.length > 0) {
        const selectedPuzzle = filteredPuzzles[Math.floor(Math.random() * filteredPuzzles.length)];

        // Apply LeadingMoves to the FEN if available
        if (selectedPuzzle.LeadingMoves) {
          const newGame = new Chess(selectedPuzzle.FEN); // Create a new game from the initial FEN
          const leadingMoves = selectedPuzzle.LeadingMoves.split(' '); // Split the leading moves

          // Apply each move in the leading moves
          leadingMoves.forEach(move => {
            const from = move.substring(0, 2); // First two characters are the 'from' square
            const to = move.substring(2, 4);   // Next two characters are the 'to' square
            const promotion = move.length === 5 ? move[4] : undefined; // Optional promotion
            newGame.move({ from, to, promotion }); // Apply the move
          });

          // Overwrite the FEN after applying the leading moves
          selectedPuzzle.FEN = newGame.fen();
        }

        setCurrentPuzzle(selectedPuzzle); // Set the puzzle with the updated FEN
      } else {
        console.log('No puzzles found within rating range or piece limit');
      }
    } catch (error) {
      console.error('Failed to load puzzle data:', error);
    } finally {
      loadingPuzzleRef.current = false; // Set ref to false immediately
      setLoadingPuzzle(false); // This will still trigger a re-render
    }
  };

  // Delay fetching the puzzle to allow puzzleRating and other dependencies to settle
  timer = setTimeout(() => {
    if (!loadingPuzzleRef.current && puzzleRating !== undefined) {
      fetchPuzzle();
    }
  }, 500); // Adjust the delay as needed

  // Cleanup the timeout on component unmount
  return () => {
    clearTimeout(timer);
  };
}, [puzzleRating]);

  useEffect(() => {
    if (currentPuzzle?.FEN) {
      setGame(new Chess(currentPuzzle.FEN));
      setFen(currentPuzzle.FEN);
      setPlayerColor(currentPuzzle?.FEN.split(' ')[1] === 'w' ? 'black' : 'white');
      setAnsweredCorrect(true);
    }
  }, [currentPuzzle?.FEN]);

  useEffect(() => {
    if (currentMoveIndex % 2 === 0 && game && moves && moves.length > 0 && currentMoveIndex < moves.length) {
      const move = moves[currentMoveIndex];
      const from = move.substring(0, 2);
      const to = move.substring(2, 4);
      const promotion = move.substring(5) || 'q';

      setTimeout(() => {
        game.move({ from, to, promotion });
        setFen(game.fen());
        setSquareStyles(squareStyling({ pieceSquare: from, history: game.history({ verbose: true }) }));
        setCurrentMoveIndex(prevIndex => prevIndex + 1);
      }, 500);
    }
  }, [game]);

  useEffect(() => {
    if (moves && moves.length > 0 && currentMoveIndex === moves.length) {
      setCurrentMoveIndex(0);
      updateFenWithFirstMove();
      flashBoardGreen().then(() => {
        adjustRating(answeredCorrect);
        setCurrentPuzzle(null);
      });
    }
  }, [currentMoveIndex, moves]);

  const hasPiece = (square) => {
    const piece = game.get(square);
    return piece;
  };

  const onSquareClick = (square) => {
    setPromotionOpen(false);
    setPromotionDetails(null);
    const piece = game.get(square);
    const historicalStyles = squareStyling({ history: game.history({ verbose: true }) });

    if (square === pieceSquare) {
      setPieceSquare("");
      setSquareStyles(historicalStyles);
      return;
    }

    if (piece && piece.color === playerColor[0]) {
      const moves = game.moves({ square: square, verbose: true });
      const squaresToHighlight = moves.map(move => move.to);

      const darkSquare = 'var(--dark-square-color)';
      const lightSquare = 'var(--light-square-color)';

      const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
        const squareColor = (curr.charCodeAt(0) - 'a'.charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0 ? lightSquare : darkSquare;
        const highlightColor = squareColor === lightSquare ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)';

        if (hasPiece(curr)) {
          return {
            ...acc,
            [curr]: {
              background: `
                linear-gradient(to bottom right, ${highlightColor} 30%, transparent 30%) 0 0,
                linear-gradient(to bottom left, ${highlightColor} 30%, transparent 30%) 100% 0,
                linear-gradient(to top left, ${highlightColor} 30%, transparent 30%) 100% 100%,
                linear-gradient(to top right, ${highlightColor} 30%, transparent 30%) 0 100%
              `,
              backgroundSize: "40% 40%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
            },
          };
        } else {
          return {
            ...acc,
            [curr]: {
              background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
              borderRadius: "50%",
            },
          };
        }
      }, {});

      setSquareStyles({
        ...historicalStyles,
        ...highlightStyles,
        [square]: { backgroundColor: "rgba(255, 255, 0, 0.4)" }
      });
      setPieceSquare(square);
    } else {
      setSquareStyles(historicalStyles);
      if (pieceSquare) {
        setCurrentSquare(square);
        setReadyForMove(true);
      }
    }
  };

  useEffect(() => {
    if (readyForMove) {
      if (pieceSquare && currentSquare && pieceSquare !== currentSquare) {
        if (isPromotionMove(pieceSquare, currentSquare)) {
          setPromotionOpen(true);
          const sourceSquare = pieceSquare;
          const targetSquare = currentSquare;
          setPromotionDetails({ sourceSquare, targetSquare });
        } else {
          handleMove(pieceSquare, currentSquare);
        }
      }
      setReadyForMove(false);
      setPieceSquare("");
    }
  }, [readyForMove]);

  const squareStyling = ({ history }) => {
    const sourceSquare = history.length && history[history.length - 1].from;
    const targetSquare = history.length && history[history.length - 1].to;

    return {
      ...(history.length && {
        [sourceSquare]: {
          backgroundColor: "rgba(255, 255, 0, 0.3)"
        },
        [targetSquare]: {
          backgroundColor: "rgba(255, 255, 0, 0.5)"
        }
      })
    };
  };

  const handlePromotion = (piece) => {
    if (promotionDetails) {
      const { sourceSquare, targetSquare } = promotionDetails;
      handleMove(sourceSquare, targetSquare, piece);
      setPromotionOpen(false);
      setPromotionDetails(null);
    }
  };

  const isPromotionMove = (sourceSquare, targetSquare) => {
    const targetRank = targetSquare[1];
    const pieceOnSource = game.get(sourceSquare);

    return pieceOnSource &&
           pieceOnSource.type === 'p' &&
           ((pieceOnSource.color === 'w' && targetRank === '8') ||
            (pieceOnSource.color === 'b' && targetRank === '1'));
  };

  const handleMove = (fromSquare, toSquare, promotion = 'q') => {
    setBoardAwarenessQuiz(false);

    const legalMoves = game.moves({ verbose: true });
    const expectedMove = moves[currentMoveIndex];
    const expectedFrom = expectedMove.substring(0, 2);
    const expectedTo = expectedMove.substring(2, 4);

    const isLegal = legalMoves.some(move => move.from === fromSquare && move.to === toSquare);

    if (isLegal) {
      const moveResult = game.move({ from: fromSquare, to: toSquare, promotion });

      if (moveResult && (fromSquare === expectedFrom && toSquare === expectedTo || game.isCheckmate())) {
        setGame(new Chess(game.fen()));
        setFen(game.fen());
        setMoveHistory(prevHistory => [...prevHistory, moveResult]);
        setCurrentMoveIndex(prevIndex => prevIndex + 1);

        const newHighlightStyles = {
          [fromSquare]: { backgroundColor: "rgba(255, 255, 0, 0.3)" },
          [toSquare]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
        };
        setSquareStyles(newHighlightStyles);
      } else {
        flashBoardRed();
        setAnsweredCorrect(false);
        game.undo();
      }
    }
  };

const adjustRating = async (wasSuccessful) => {
  const newPuzzleCount = puzzleCount + 1;
  setPuzzleCount(newPuzzleCount);

  const weightedCount = Math.min(newPuzzleCount, 100);
  const previousSuccessfulPuzzles = (puzzleAccuracy / 100) * (weightedCount - 1);
  const successfulPuzzles = wasSuccessful ? previousSuccessfulPuzzles + 1 : previousSuccessfulPuzzles;

  const newPuzzleAccuracy = (successfulPuzzles / weightedCount) * 100;
  setPuzzleAccuracy(newPuzzleAccuracy);

  let ratingChange;
  const puzzleCountThresholds = [
    { threshold: 10, change: 90 },
    { threshold: 20, change: 50 },
    { threshold: 50, change: 30 },
    { threshold: 100, change: 20 },
    { threshold: Infinity, change: 10 }
  ];

  const foundThreshold = puzzleCountThresholds.find(threshold => puzzleCount < threshold.threshold);
  const baseChange = foundThreshold ? foundThreshold.change : 20;

  if (newPuzzleAccuracy > 86.5 && wasSuccessful) {
    ratingChange = baseChange / 2;
  } else if (newPuzzleAccuracy > 86.5 && !wasSuccessful) {
    ratingChange = -2;
  } else if (newPuzzleAccuracy < 83.5 && !wasSuccessful) {
    ratingChange = -baseChange;
  } else if (newPuzzleAccuracy < 83.5 && wasSuccessful) {
    ratingChange = 2;
  } else if (wasSuccessful) {
    ratingChange = 2;
  } else {
    ratingChange = -2;
  }

  let newPuzzleRating = puzzleRating + ratingChange;

  // Apply the rating change first
  setPuzzleRating(newPuzzleRating);

  // Adding a small delay before updating the rating
  await new Promise((resolve) => setTimeout(resolve, 1500));

  // Clamp the rating if it goes below 400 or above 3200
  if (newPuzzleRating < 400) {
    newPuzzleRating = 400;
  } else if (newPuzzleRating > 3200) {
    newPuzzleRating = 3200;
  }

  setPuzzleRating(newPuzzleRating); // Set clamped rating after the change is triggered

  if (user) {
    try {
      const userRef = doc(db, "users", user.uid);
      
      // Encode the new stats into a single string
      const encodedStats = encodeendgamePuzzleStats(newPuzzleRating, newPuzzleAccuracy, newPuzzleCount);
      
      await updateDoc(userRef, {
        endgamePuzzleStats: encodedStats
      });
    } catch (error) {
      console.error("Failed to update Firestore:", error);
    }
  }
};

// Helper function to encode the puzzle stats into a string
const encodeendgamePuzzleStats = (rating, accuracy, count) => {
  return `r${Math.round(rating)}a${accuracy.toFixed(1)}c${count}`;
};

  const flashBoardGreen = async () => {
    const allSquares = [];
    for (let row = 1; row <= 8; row++) {
      for (let col of 'abcdefgh') {
        allSquares.push(col + row);
      }
    }

    const flashStyle = allSquares.reduce((styles, square) => {
      styles[square] = { backgroundColor: "rgba(0, 255, 0, .5)" };
      return styles;
    }, {});

    const defaultStyle = allSquares.reduce((styles, square) => {
      styles[square] = { backgroundColor: "transparent" };
      return styles;
    }, {});

    for (let i = 0; i < 1; i++) {
      setSquareStyles(flashStyle);
      await new Promise(resolve => setTimeout(resolve, 400));
      setSquareStyles(defaultStyle);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  };

  const flashBoardRed = async () => {
    const allSquares = [];
    for (let row = 1; row <= 8; row++) {
      for (let col of 'abcdefgh') {
        allSquares.push(col + row);
      }
    }

    const currentStyles = { ...squareStyles };
    const flashStyle = allSquares.reduce((styles, square) => {
      styles[square] = { backgroundColor: "rgba(255, 0, 0, 0.5)" };
      return styles;
    }, {});

    setSquareStyles(flashStyle);
    await new Promise(resolve => setTimeout(resolve, 500));
    setSquareStyles(currentStyles);
  };

  const onDrop = ({ sourceSquare, targetSquare }) => {
    const promotion = 'q';
    const legalMoves = game.moves({ verbose: true });

    const moveIsLegal = legalMoves.some(move => move.from === sourceSquare && move.to === targetSquare);

    if (!moveIsLegal) {
      return 'snapback';
    }

    const piece = game.get(sourceSquare);
    const isPromotion = piece.type === 'p' && (targetSquare[1] === '8' || targetSquare[1] === '1');

    if (isPromotion) {
      setPromotionOpen(true);
      setPromotionDetails({ sourceSquare, targetSquare });
    } else {
      handleMove(sourceSquare, targetSquare);
    }
  };

  const handleGiveUp = async () => {
    setAnsweredCorrect(false);

    game.load(currentPuzzle?.FEN);
    setFen(game.fen());

    for (let index = 0; index < moves.length; index++) {
      const move = moves[index];
      const from = move.substring(0, 2);
      const to = move.substring(2, 4);
      const promotion = move.length > 4 ? move.charAt(4) : undefined;

      await new Promise(resolve => setTimeout(resolve, 1000));

      const result = game.move({ from, to, promotion });
      setFen(game.fen());

      const newHighlightStyles = {
        [from]: { backgroundColor: "rgba(255, 255, 0, 0.3)" },
        [to]: { backgroundColor: "rgba(255, 255, 0, 0.5)" }
      };
      setSquareStyles(newHighlightStyles);

      if (result && game.isCheckmate()) {
        break;
      }
    }

    await new Promise(resolve => setTimeout(resolve, 1000));

    await flashBoardGreen();

    adjustRating(false);
    setCurrentMoveIndex(0);
    updateFenWithFirstMove();
    setCurrentPuzzle(null);
  };

  const updateFenWithFirstMove = () => {
    if (currentPuzzle && currentPuzzle.FEN && currentPuzzle.Moves) {
      const game = new Chess(currentPuzzle.FEN);
      const moves = currentPuzzle.Moves.split(' ');

      if (moves.length > 0) {
        const firstMove = moves[0];
        game.move(firstMove);
        const newFen = game.fen();
        setPreviousFen(newFen);
      }
    }
  };

  const openInLichess = () => {
    const fen = previousFen;
    const url = `https://lichess.org/analysis/standard/${encodeURIComponent(fen)}?openBrowser=true`;
    window.open(url, "_blank");
  };

  const updateShowQuiz = (show) => {
    setShowQuiz(show);
  };

  const handleQuizRequest = () => {
    setBoardAwarenessQuiz(true);
  };

  const updateQuizAnswer = (answer) => {
    setQuizAnswer(answer);
  };

  const handleCloseMessage = () => {
    setShowLoginMessage(false);
  };

return (
  <div className="game-layout">
    {showLoginMessage && (
      <div className="login-message">
        <button className="close-button" onClick={handleCloseMessage}>×</button>
        <p><a href="/login">Log in</a> to save your puzzle data.</p>
      </div>
    )}

    {/* Main container to hold both chessboard and buttons */}
    <div className='chessboard-container'>
      <ChessboardCustom
        position={fen}
        onMove={handleMove}
        degreeOfBlindness='Normal'
        orientation={playerColor}
        squareStyles={squareStyles}
        onSquareClick={onSquareClick}
        onDrop={onDrop}
        updateChessboardSize={setChessboardSize}
        showQuiz={showQuiz}
        updateQuizAnswer={updateQuizAnswer}
      />

      {boardAwarenessQuiz && (
        <QuizComponent
          fen={fen}
          playerColor={playerColor}
          chessboardSize={chessboardSize}
          updateShowQuiz={updateShowQuiz}
          quizAnswer={quizAnswer}
          request="true"
        />
      )}
    </div>

    {promotionOpen && (
      <div className='promotion-modal-container'>
        <PromotionModal
          onPromote={handlePromotion}
          pieces={['q', 'r', 'n', 'b']}
          color={playerColor === 'white' ? 'w' : 'b'}
        />
      </div>
    )}

    {/* Info Container */}
    <div className='info-container'>
      <div className='help-link'>
        <a href="/puzzles/help" target="_blank" rel="noopener noreferrer">Help</a>
      </div>
      {/* Rating Information */}
      <div className="rating-info">
        <p>
          <span className='label'>Rating:</span>
          {' '}{puzzleRating}
        </p>
        <p className='no-media'>
          <span className='label'>Accuracy:</span>
          {' '}{puzzleAccuracy ? parseFloat(puzzleAccuracy.toFixed(1)) : '0.0'}%
        </p>
        <p className='no-media'><span className='label'>Puzzle Count:</span> {puzzleCount}</p>
      </div>

      {/* Buttons */}
      <div className='buttons-container'>
        <button className='puzzle-button' onClick={handleGiveUp}>Give Up</button>
        <button className='puzzle-button' onClick={openInLichess}>Analyze Last Puzzle</button>
        <button className='puzzle-button' onClick={handleQuizRequest}>Awareness Quiz</button>
      </div>
    </div>
  </div>
);
}

export default EndgamePuzzles;
