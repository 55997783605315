import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/help.css'; // Ensure correct path to your CSS file
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const HelpPage = () => {
  return (
    <div className="help-container">
      <EnableScrollOnPage />
      <h1>Welcome</h1>
      <p>Welcome to TrainChess.net. We’re excited that you’re here, and we hope you came here to practice chess and learn how to get better! This page is your gateway to all the tools and resources we offer designed to help you improve. <br /><br />Below, you'll find links to various help topics that will guide you through our different features. From blindfold training to puzzles, visualization techniques, and pattern recognition, we’ve tailored our resources to give you tools to train the most important elements of chess. </p>

      {/* Visualization Section */}
      <section className="help-section">
        <h2>Visualization</h2>
        <ul>
          {/* Link to Play Blindfold Help */}
          <li>
            <Link to="/playblindfold/help" className="help-page-link">Play Blindfold</Link>
          </li>
          {/* Link to Blindfold Puzzles Help */}
          <li>
            <Link to="/blindfoldpuzzles/help" className="help-page-link">Blindfold Puzzles</Link>
          </li>
          {/* Link to Play Blindfold Help */}
          <li>
            <Link to="/coordinatetrainer/help" className="help-page-link">Coordinate Trainer</Link>
          </li>


          {/* Add other Visualization-related links as needed */}
        </ul>
      </section>

      {/* Pattern Recognition Section */}
      <section className="help-section">
        <h2>Pattern Recognition</h2>
        <ul>
          {/* Link to Normal Puzzles Help */}
          <li>
            <Link to="/puzzles/help" className="help-page-link">Normal Puzzles</Link>
          </li>
          {/* Link to Blindfold Puzzles Help */}
          <li>
            <Link to="/blindfoldpuzzles/help" className="help-page-link">Blindfold Puzzles</Link>
          </li>
          {/* Link to Calculation Puzzles Help */}
          <li>
            <Link to="/calculationpuzzles/help" className="help-page-link">Calculation Puzzles</Link>
          </li>
          {/* Link to Calculation Puzzles Help */}
          <li>
            <Link to="/puzzles/help" className="help-page-link">Endgame Puzzles</Link>
          </li>
          {/* Link to Calculation Puzzles Help */}
          <li>
            <Link to="/findthefork/help" className="help-page-link">Find the Fork</Link>
          </li>

        </ul>
      </section>

      <section className="help-section">
        <h2>Other Resources</h2>
        <ul>
          {/* Link to Normal Puzzles Help */}
          <li>
            <Link to="/ratingmetrics" className="help-page-link">Our Unique Rating System</Link>
          </li>
          <li>
            <Link to="/algebraicnotation" className="help-page-link">Learning Algebraic Notation</Link>
          </li>
        </ul>
      </section>

      {/* About Section for Contact and Website Info */}
      <section className="further-help">
        <h3>Need more help or want to learn more about us?</h3>
        <p>Visit our <Link to="/about" className="further-help-link">about page</Link> or <a href="/contact">contact us</a> with questions or comments.</p>
      </section>
    </div>
  );
};

export default HelpPage;
