import React, { Component } from 'react';
import { Chess } from "chess.js";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Move History Container
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const renderMoveHistory = (component, history, squareStyling) => {
  const MIN_ROWS = 5; // Minimum number of rows to display initially

  const goToStartPosition = () => {
    component.setState({
      displayFen: component.state.initialFen,
      selectedMoveIndex: -1,
    });
  };

  const goToPreviousMove = () => {
    if (component.state.selectedMoveIndex <= 0) {
      if (component.state.selectedMoveIndex === 0) {
        goToStartPosition();
      }
    } else {
      const newMoveIndex = component.state.selectedMoveIndex - 1;
      goToMove(newMoveIndex);
    }
  };

  const goToNextMove = () => {
    const newMoveIndex = Math.min(component.state.selectedMoveIndex + 1, component.state.history.length - 1);
    goToMove(newMoveIndex);
  };

  const goToMove = (moveIndex) => {
    const { history } = component.state;
    const temporaryGame = new Chess(component.state.initialFen);
    history.slice(0, moveIndex + 1).forEach(move => {
      temporaryGame.move(move.san);
    });
    const tempFen = temporaryGame.fen();
    const updatedHistory = temporaryGame.history({ verbose: true });
    const newSquareStyles = squareStyling({ pieceSquare: "", history: updatedHistory });

    if (tempFen === component.state.fen) {
      returnToCurrentPosition(moveIndex);
    } else {
      component.setState({
        displayFen: tempFen,
        squareStyles: newSquareStyles,
        isViewingCurrentPosition: false,
        selectedMoveIndex: moveIndex,
      });
    }
  };

  const returnToCurrentPosition = (moveIndex) => {
    component.setState({
      displayFen: null,
      isViewingCurrentPosition: true,
      selectedMoveIndex: moveIndex,
    });
  };

  const copyFenToClipboard = () => {
    const fen = component.state.fen;
    navigator.clipboard.writeText(fen).then(() => {
      console.log('FEN copied to clipboard:', fen);
    }).catch(err => {
      console.error('Failed to copy FEN to clipboard:', err);
    });
  };

  // Create empty rows for placeholders if there are less than MIN_ROWS entries
  const rows = history.reduce((acc, move, index) => {
    if (index % 2 === 0) {
      acc.push([move]);
    } else {
      acc[acc.length - 1].push(move);
    }
    return acc;
  }, []);

  // Add empty rows to ensure at least MIN_ROWS are displayed
  while (rows.length < MIN_ROWS) {
    rows.push([]);
  }

  return (
    <div className="move-history-container">
      <div className="history-controls">
        <button onClick={() => goToStartPosition()}>
          <img src={process.env.PUBLIC_URL + "/buttons/backwardAll.png"} alt="Start Position" />
        </button>
        <button onClick={() => goToPreviousMove()}>
          <img src={process.env.PUBLIC_URL + "/buttons/backward.png"} alt="Previous Move" />
        </button>
        <button onClick={() => goToNextMove()}>
          <img src={process.env.PUBLIC_URL + "/buttons/forward.png"} alt="Next Move" />
        </button>
        <button onClick={() => goToMove(history.length - 1)}>
          <img src={process.env.PUBLIC_URL + "/buttons/forwardAll.png"} alt="Last Move" />
        </button>
      </div>
      <div className="move-history-scroll" ref={component.moveHistoryRef}>
        <table>
          <tbody>
            {rows.map((pair, pairIndex) => (
              <tr key={pairIndex}>
                <td className="move-number">{pairIndex + 1}</td>
                <td
                  onClick={() => pair[0] && goToMove(pairIndex * 2)}
                  className={`${component.state.selectedMoveIndex === pairIndex * 2 ? 'selected ' : ''}move`}>
                  {pair[0] ? pair[0].san : ""}
                </td>
                <td
                  onClick={() => pair[1] && goToMove(pairIndex * 2 + 1)}
                  className={`${pair[1] && component.state.selectedMoveIndex === pairIndex * 2 + 1 ? 'selected ' : ''}move`}>
                  {pair[1] ? pair[1].san : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="history-controls">
        <button onClick={component.handleTakeBackMove}>
          <img src={process.env.PUBLIC_URL + "/buttons/takeBack.png"} alt="Take Back Move" />
        </button>
        <button onClick={component.handleHint}>
          <img src={process.env.PUBLIC_URL + "/buttons/lightbulb.png"} alt="Take Back Move" />
        </button>
        <div className="share-button-container">
          <button ref={component.shareButtonRef} onClick={() => component.setState(prevState => ({ showShareOptions: !prevState.showShareOptions }))} className="toolbar-button">
            <img src={process.env.PUBLIC_URL + "/buttons/open.png"} alt="Take Back Move" />
          </button>
          {component.state.showShareOptions && (
            <div ref={component.dropdownRef} className="share-options">
              <button onClick={copyFenToClipboard} className="share-option">Copy FEN</button>
              <button onClick={component.copyPgnToClipboard} className="share-option">Copy PGN</button>
              <button onClick={component.openInLichess} className="share-option">Open in Lichess</button>
            </div>
          )}
        </div>
        <button onClick={component.handleResigns}>
          <img src={process.env.PUBLIC_URL + "/buttons/resigns.png"} alt="Resign" />
        </button>
      </div>
    </div>
  );
};
